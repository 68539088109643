import { useEffect, useMemo, useState } from 'react';
import { Money } from 'shared/types/product/Money';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import mapCosts from 'helpers/utils/mapCosts';
import { useCart } from 'frontastic';
import { CostsProps, CostRef } from '../types';

export type UseCostsData = (props: Pick<CostsProps, 'dataReference' | 'order'>) => {
  costsToRender: CostRef[];
  total: CostRef;
  loading: boolean;
};

export type CostData = {
  subtotal: Money;
  net: Money;
  shipping: Money;
  discount: Money;
  total: Money;
};

const useCostsData: UseCostsData = ({ dataReference = 'cart', order }) => {
  const { data } = useCart();
  const { currency } = useI18n();
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const [loading, setLoading] = useState(true);

  const skeletonMoney: Money = useMemo(() => {
    return { fractionDigits: 2, centAmount: 0, currencyCode: currency };
  }, [currency]);

  const cartCosts = useMemo(() => {
    const costs = {
      subtotal: data?.sumOfLineItemsGross,
      net: data?.sumOfLineItemsNet,
      shipping: data?.shippingPriceGross,
      discount: data?.discountCodes?.length !== 0 ? data?.discountGross : null,
      total: data?.sum,
    };
    return costs as CostData;
  }, [data]);

  const skeletonCosts = useMemo(
    () => [
      {
        key: 'subtotal',
        label: formatCartMessage({ id: 'subtotal', defaultMessage: 'Total gross' }),
        value: skeletonMoney,
      },
      {
        key: 'net',
        label: formatCartMessage({ id: 'net', defaultMessage: 'Total net' }),
        value: skeletonMoney,
      },
      {
        key: 'shipping',
        label: formatCartMessage({ id: 'shipping.estimate', defaultMessage: 'Est. Shipping' }),
        value: skeletonMoney,
      },
      {
        key: 'discount',
        label: formatCartMessage({
          id: 'discount',
          defaultMessage: 'Discount',
        }),
        value: skeletonMoney,
      },
    ],
    [formatCartMessage, skeletonMoney],
  ) as CostRef[];

  const costsToRender = useMemo(() => {
    const dataIsHydrated =
      (dataReference === 'order' && order) ||
      (dataReference === 'cart' && cartCosts?.total?.centAmount && cartCosts.total.centAmount > 0);

    if (!dataIsHydrated) return [];

    const costsToUse = dataReference === 'cart' ? cartCosts : mapCosts({ order, currency });

    const costs = [...skeletonCosts].map(({ key, label }) => {
      return {
        key,
        label,
        value: costsToUse[key as keyof typeof costsToUse],
      };
    }) as CostRef[];

    return costs;
  }, [currency, dataReference, order, skeletonCosts, cartCosts]);

  useEffect(() => {
    if (costsToRender.length) setLoading(false);
  }, [costsToRender]);

  const total: CostRef = {
    key: 'total',
    label: formatCartMessage({ id: 'total', defaultMessage: 'Total' }),
    value: dataReference === 'cart' && cartCosts.total ? cartCosts.total : (order?.sum as Money),
  };

  return { loading, costsToRender: costsToRender.length ? costsToRender : skeletonCosts, total };
};

export default useCostsData;
