import React, { FC, ReactNode, useMemo } from 'react';
import { useParams } from 'next/navigation';
import useCloseFlyouts from 'helpers/hooks/useCloseFlyouts';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { CartSlideoutProps } from './type';
import CartItem from '../cart/components/cart-item';
import { EmptyState } from '../empty-state';
import OrderOverview from '../order-overview';
import CheckoutButton from '../order-overview/components/checkout-button';
import useCostsData from '../order-payment-section/hooks/useCostsData';

const CartSlideout: FC<CartSlideoutProps> = ({ emptyStateImage, emptyStateTitle }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const title = formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' });

  const { data, isEmpty, hasOutOfStockItems } = useCart();
  const closeFlyouts = useCloseFlyouts();
  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference: 'cart' });

  const costData = {
    locale,
    loading,
    costsToRender,
    total,
    freeShipping: data?.freeShipping,
  };

  const discountCodes = data?.discountCodes;

  const ctaButton: ReactNode = useMemo(() => {
    return (
      <CheckoutButton
        useButtonComponent
        buttonComponentProps={{
          variant: 'primary',
          className: 'w-full',
          'data-test': 'drawer-cart-page-order-summary-cta-button',
        }}
        text={formatCartMessage({ id: 'cart.go', defaultMessage: 'Go to cart' })}
        link={'/cart'}
        onClick={closeFlyouts}
        disabled={isEmpty || hasOutOfStockItems}
      />
    );
  }, [closeFlyouts, formatCartMessage, hasOutOfStockItems, isEmpty]);

  return (
    <div data-test="cart-panel" className="h-[calc(100vh-56px)] grow">
      {isEmpty ? (
        <EmptyState className="grow" image={emptyStateImage} title={emptyStateTitle} />
      ) : (
        <div className="flex h-full flex-col">
          <div className="shrink grow gap-8 divide-y divide-neutral-400 overflow-auto px-12 pt-8 md:px-22">
            {data?.lineItems?.map((lineItem) => (
              <CartItem
                key={lineItem.lineItemId}
                item={lineItem}
                variant="mobile"
                page="drawer-cart"
                discountCodes={discountCodes}
              />
            ))}
          </div>
          <OrderOverview costsPropsFromParentPage={costData} ctaButton={ctaButton} page="drawer-cart" title={title} />
        </div>
      )}
    </div>
  );
};

export default CartSlideout;
