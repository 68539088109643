import React, { useMemo } from 'react';
import { useParams } from 'next/navigation';
import { Money } from 'shared/types/product/Money';
import TextLabel from 'components/icons/labels/TextLabel';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import Typography from '../../../atoms/typography';

interface Props {
  price?: Money;
  variant: 'product-tile' | 'search' | 'product-summary' | 'cart-item' | 'drawer-wishlist';
  vatText?: string;
  additionalClassName?: string;
  disabled?: boolean;
  percentOfDiscount?: number;
  strikeThroughPrice?: Money;
  taxRate?: string;
}

const Prices: React.FC<Props> = ({
  price,
  variant,
  vatText,
  additionalClassName = '',
  disabled,
  percentOfDiscount,
  strikeThroughPrice,
  taxRate,
}) => {
  const { locale } = useParams();

  const fontSize = useMemo(() => {
    switch (variant) {
      case 'product-tile':
        return 'text-24 leading-[30px]';
      case 'search':
        return 'text-14';
      case 'product-summary':
        return 'text-32 leading-10';
      case 'cart-item':
        return 'text-18 leading-[22.5px]';
      case 'drawer-wishlist':
        return 'text-16 leading-5';
      default:
        return 'text-sm';
    }
  }, [variant]);

  const acceptedVATaxVariants = ['product-tile', 'cart-item', 'product-summary'];

  const dataTest = variant === 'product-tile' ? 'unit-price' : variant === 'cart-item' ? 'line-item-unit-price' : null;

  // Business rule to only show the percentage label if the value is 10% or above
  const displayPercentageLabel = percentOfDiscount ? percentOfDiscount >= 10 : false;

  return (
    <div
      className={`flex flex-col ${variant === 'drawer-wishlist' ? 'mt-0' : 'mt-8'} ${
        variant === 'product-tile' && !strikeThroughPrice ? 'pt-22' : ''
      } ${additionalClassName}`}
    >
      {variant === 'cart-item' && strikeThroughPrice && !disabled && (
        <div className="flex flex-col gap-8">
          {displayPercentageLabel && (
            <div className="flex">
              <TextLabel variant="discount">-{percentOfDiscount}%</TextLabel>
            </div>
          )}
          <Typography className="text-14 font-bold leading-5 text-neutral-3 line-through">
            {CurrencyHelpers.formatForCurrency(strikeThroughPrice ?? '', locale)}
          </Typography>
        </div>
      )}

      {(variant === 'product-tile' || variant === 'product-summary') && strikeThroughPrice && !disabled && (
        <div className="flex flex-col gap-4">
          <div className={`${variant === 'product-tile' ? 'sm:h-22' : ''} flex items-end gap-12 align-middle`}>
            <Typography className="text-16 font-bold leading-5 text-neutral-3 line-through">
              {CurrencyHelpers.formatForCurrency(strikeThroughPrice ?? '', locale)}
            </Typography>
            {displayPercentageLabel && <TextLabel variant="discount">-{percentOfDiscount}%</TextLabel>}
          </div>
        </div>
      )}
      {variant === 'drawer-wishlist' && strikeThroughPrice && !disabled && (
        <div className="mb-8 flex gap-12">
          <Typography className="text-right text-14 text-neutral-3 line-through">
            {CurrencyHelpers.formatForCurrency(strikeThroughPrice ?? '', locale)}
          </Typography>
          {displayPercentageLabel && <TextLabel variant="discount">-{percentOfDiscount}%</TextLabel>}
        </div>
      )}

      <Typography
        data-test={dataTest}
        className={`${fontSize} font-bold ${disabled ? 'text-neutral-2' : 'text-neutral-4'}`}
      >
        {CurrencyHelpers.formatForCurrency(price ?? '', locale)}
      </Typography>

      {acceptedVATaxVariants.includes(variant) && vatText ? (
        <Typography
          className={`mt-2 text-14 leading-[17.5px] ${
            disabled
              ? 'text-neutral-2'
              : variant === 'product-summary' || variant === 'cart-item'
              ? '!text-neutral-3'
              : 'text-neutral-4'
          }`}
        >
          {vatText}
        </Typography>
      ) : null}
      {acceptedVATaxVariants.includes(variant) && taxRate ? (
        <Typography
          className={`mt-2 text-14 leading-[17.5px] ${
            disabled
              ? 'text-neutral-2'
              : variant === 'product-summary' || variant === 'cart-item'
              ? '!text-neutral-3'
              : 'text-neutral-4'
          }`}
        >
          {taxRate}
        </Typography>
      ) : null}
    </div>
  );
};

export default Prices;
