import { FC } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import DocumentIcon from 'components/icons/DocumentIcon';
import { useFormat } from 'helpers/hooks/useFormat';

export type ProductCardUrl = {
  sortId?: string;
  localizedLabel?: string;
  value: string;
};
export interface ProductCardProps {
  productCardUrl: ProductCardUrl;
}

const ProductCard: FC<ProductCardProps> = ({ productCardUrl }) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <Link
      target="_blank"
      link={productCardUrl.value}
      className="mt-4 flex items-center gap-8 py-4 align-middle"
      onClick={handleClick}
      rel="noopener noreferrer"
    >
      <Typography className="text-14 leading-[17.5px] text-neutral-4">
        {formatMessage({ id: 'productCard', defaultMessage: 'Product Card' })}
      </Typography>
      <span className="text-neutral-3">
        <DocumentIcon scale={0.75} />
      </span>
    </Link>
  );
};

export default ProductCard;
