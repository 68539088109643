function EyeIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12.002 15.577c1.133 0 2.096-.397 2.887-1.19.792-.793 1.188-1.756 1.188-2.89 0-1.132-.397-2.095-1.19-2.886-.793-.792-1.756-1.188-2.89-1.188-1.132 0-2.095.397-2.886 1.19-.792.793-1.188 1.756-1.188 2.89 0 1.132.397 2.095 1.19 2.886.793.792 1.756 1.188 2.89 1.188zM12 14.2c-.75 0-1.387-.262-1.912-.787A2.604 2.604 0 019.3 11.5c0-.75.263-1.387.788-1.913A2.604 2.604 0 0112 8.8c.75 0 1.388.262 1.913.787.525.526.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.604 2.604 0 0112 14.2zm.001 4.3c-2.3 0-4.394-.634-6.286-1.903-1.89-1.269-3.283-2.968-4.176-5.097.893-2.13 2.285-3.829 4.175-5.097C7.604 5.134 9.7 4.5 12 4.5c2.3 0 4.395.634 6.286 1.903 1.89 1.268 3.283 2.967 4.177 5.097-.894 2.13-2.286 3.829-4.176 5.097-1.89 1.269-3.985 1.903-6.285 1.903zm0-1.5a9.545 9.545 0 005.187-1.488A9.774 9.774 0 0020.8 11.5a9.773 9.773 0 00-3.612-4.013A9.545 9.545 0 0012 6a9.545 9.545 0 00-5.187 1.487A9.773 9.773 0 003.2 11.5a9.773 9.773 0 003.613 4.012A9.545 9.545 0 0012 17z"
      ></path>
    </svg>
  );
}

export default EyeIcon;
