import React, { useMemo } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Above744 from 'components/commercetools-ui/organisms/content/shapes/Above744';
import Below744 from 'components/commercetools-ui/organisms/content/shapes/Below744';
import { Reference } from 'types/reference';
import Image, { ImageProps } from 'frontastic/lib/image';

export interface HeroProps {
  image: ImageProps;
  title: string;
  subtitle: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  backgroundColor?: string;
  clickable?: boolean;
}

const Hero: React.FC<HeroProps> = ({
  image,
  title,
  subtitle,
  ctaLabel,
  ctaReference,
  backgroundColor,
  clickable = false,
}) => {
  const cropFocus = useMemo(() => {
    if (image.gravity?.coordinates) {
      const right = Math.ceil((image.gravity?.coordinates.x / (image.media?.width as number)) * 100);
      const top = Math.ceil((image.gravity?.coordinates.y / (image.media?.height as number)) * 100);

      return `right ${right.toString()}% top ${top.toString()}%`;
    }

    if (image.gravity?.mode === 'center') {
      return 'center';
    }

    return 'initial';
  }, [image]);

  const cta = ctaLabel && (
    <Button>
      <Typography as="span" className="text-16">
        {ctaLabel}
      </Typography>
    </Button>
  );

  const heroContent = image && (
    <div className="relative h-[390px] md:h-[320px] lg:h-[420px]">
      <Image
        {...image}
        priority
        loading="eager"
        alt={title}
        fill
        style={{ objectFit: 'cover', objectPosition: cropFocus, position: 'absolute' }}
      />
      <div className="absolute !block h-full w-full px-20 py-24 md:p-32">
        <div className="flex h-full flex-col items-start gap-24 md:gap-32">
          <div className={`absolute inset-0 overflow-hidden`} style={{ color: backgroundColor }}>
            <div className="hidden h-full w-full md:block md:[&>svg]:!h-full md:[&>svg]:!w-auto">
              <Above744 />
            </div>
            <div className={`md:hidden ${ctaLabel ? '' : '[&>svg]:!h-[190px]'}`}>
              <Below744 />
            </div>
          </div>

          <div className="flex flex-col gap-16 md:h-full md:w-[223px] md:justify-end md:gap-12">
            <Typography
              as="h5"
              className="text-24 leading-[30px] text-neutral-6 md:text-28 md:leading-9 lg:text-32 lg:leading-10"
            >
              {title}
            </Typography>
            <Typography className=" text-16 leading-5 text-neutral-4">{subtitle}</Typography>
          </div>
          {cta && <div className="md:w-[223px]">{clickable ? cta : <Link link={ctaReference}>{cta}</Link>}</div>}
        </div>
      </div>
    </div>
  );

  return <Container>{clickable ? <Link link={ctaReference}>{heroContent}</Link> : heroContent}</Container>;
};

export default Hero;
