function CheckmarkIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M9.55 17.654l-5.335-5.335 1.07-1.069 4.265 4.265 9.165-9.165 1.07 1.07L9.55 17.653z"
      ></path>
    </svg>
  );
}

export default CheckmarkIcon;
