import React from 'react';
import { Discount } from 'shared/types/cart';
import CouponIcon from 'components/icons/CouponIcon';
import TextLabel from 'components/icons/labels/TextLabel';

export interface DiscountLabelProps {
  discount: Discount;
}

export const DiscountLabel: React.FC<DiscountLabelProps> = ({ discount }) => (
  <TextLabel variant="discount-code">
    <>
      <CouponIcon scale={0.67} />
      {discount.code}
    </>
  </TextLabel>
);
