'use client';
import React from 'react';
import Image from 'next/image';
import Container from 'components/commercetools-ui/atoms/container';
import Typography from 'components/commercetools-ui/atoms/typography';
import SelgrosLogo from 'components/icons/logos/SelgrosLogo';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import ImageMaintenanceDesktop from 'public/images/maintenance-desktop.png';
import ImageMaintenanceInfo from 'public/images/maintenance-info.png';
import ImageMaintenanceMobile from 'public/images/maintenance-mobile.png';

const Maintenance: React.FC = () => {
  const [isAboveTablet] = useMediaQuery(tablet);
  const imageMaintenance = isAboveTablet ? ImageMaintenanceDesktop : ImageMaintenanceMobile;

  return (
    <div className="flex min-h-screen flex-col items-stretch">
      <header className="relative h-fit w-full border-b-[1.5px] border-neutral-400 bg-white">
        <Container>
          <div aria-label="Top" className="flex w-full items-center justify-between px-16 md:px-24 lg:px-20 xl:px-46">
            <div className="ml-0 flex w-full justify-start gap-12 py-12 xl:gap-24 xl:pb-20 xl:pt-24">
              <div className="flex md:mt-0 xl:pl-0">
                <div className="flex w-161 items-center justify-between [&>svg]:h-28 xl:[&>svg]:h-32 xl:[&>svg]:w-180">
                  <SelgrosLogo />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </header>
      <div className="flex-1 overflow-y-auto bg-base-accent-3">
        <div className="mx-auto max-w-[988px]">
          <div
            className="mt-65 flex flex-col max-md:gap-32 md:mt-79 md:w-full md:flex-row-reverse"
            style={{ height: `${isAboveTablet ? 'auto' : `calc(100vh - 175px)`}` }}
          >
            <div className="max-md:mx-auto md:w-[50%] md:max-lg:mr-24">
              <Image
                loader={({ src }) => src}
                src={imageMaintenance}
                alt={'Problemów technicznych'}
                style={{
                  objectFit: 'fill',
                  objectPosition: 'center',
                  marginLeft: 'auto',
                }}
              />
            </div>
            <div className="flex h-full flex-col justify-between md:w-[50%] md:gap-32 md:self-center md:max-lg:ml-24">
              <div className="max-md:px-20">
                <Typography className="pb-8 text-24 font-bold leading-[30px] text-neutral-700">
                  Szanowni klienci,
                </Typography>
                <Typography className="text-16 font-bold leading-[20px] text-neutral-700">
                  Nasz sklep internetowy doświadcza obecnie problemów technicznych. Intensywnie pracujemy nad ich
                  rozwiązaniem.
                </Typography>
                <Typography className="pb-16 text-16 font-bold leading-[20px] text-neutral-700">
                  Przepraszamy za wszelkie niedogodności i dziękujemy za wyrozumiałość.
                </Typography>
                <Typography className="text-16 font-bold leading-[20px] text-neutral-700">
                  Zespół Selgros24.pl
                </Typography>
              </div>
              <div className="flex justify-between bg-neutral-5 p-20 max-md:mt-66">
                <div>
                  <Typography className="pb-8 text-16 leading-[20px] text-neutral-700">Infolinia</Typography>
                  <Typography className="pb-8 text-24 font-bold leading-[30px] text-neutral-700">
                    800 505 105
                  </Typography>
                  <Typography className="text-16 leading-[20px] text-neutral-700">
                    Poniedziałek - Piątek: 8:00 - 17:00
                  </Typography>
                </div>
                <div className="h-76 w-76 self-center">
                  <Image
                    loader={({ src }) => src}
                    src={ImageMaintenanceInfo}
                    alt={'Infolinia'}
                    style={{
                      objectFit: 'fill',
                      objectPosition: 'center',
                      marginLeft: 'auto',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer aria-labelledby="footer-heading" className="w-full bg-neutral-6">
        <Container>
          <div className="flex flex-col gap-10 p-20 text-neutral-5 md:flex-row md:justify-between md:px-46 md:pb-19">
            <Typography className="text-12 leading-[15px]">Selgros 2024 © wszystkie prawa zastrzeżone</Typography>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Maintenance;
