import { useCallback } from 'react';
import * as yup from 'yup';

const useYupValidation = () => {
  const fieldValidation = useCallback(
    (schema: yup.AnyObjectSchema, data: Record<string, any>, name: string, value: string) => {
      try {
        const fieldToValidate = { ...data, [name]: value };
        schema.validateSync(fieldToValidate, { abortEarly: false });

        return { isValid: true };
      } catch (errors: any) {
        const validationErrors = errors.inner.reduce(
          (allErrors: any, currentError: any) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {},
        );

        const fieldError = Object.keys(validationErrors).find((fieldName) => fieldName === name);
        return fieldError ? { isValid: false, error: validationErrors[fieldError].message } : { isValid: true };
      }
    },
    [],
  );

  const formValidation = useCallback((schema: yup.AnyObjectSchema, data: Record<string, any>) => {
    try {
      schema.validateSync(data);
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  return { fieldValidation, formValidation };
};

export default useYupValidation;
