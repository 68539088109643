import React from 'react';
import CloseIcon from 'components/icons/CloseIcon';
import QuestionThinIcon from 'components/icons/QuestionThinIcon';

export type TooltipProps = {
  message: string;
  show: boolean;
  setShow: (show: boolean) => void;
};

const Tooltip: React.FC<TooltipProps> = ({ message, show, setShow }) => {
  return (
    <div className="flex flex-col md:relative">
      <span className="flex justify-center hover:cursor-pointer" onMouseEnter={() => setShow(true)}>
        <QuestionThinIcon />
      </span>
      <div
        className={`shadow-custom absolute bottom-full left-0 mb-8 flex gap-8 bg-white p-12 pl-20 max-md:w-full md:w-350 ${
          !show ? 'hidden' : ''
        }`}
      >
        <span className="flex-1 text-16 leading-[24px] text-neutral-4">{message}</span>
        <span className="flex justify-center hover:cursor-pointer" onClick={() => setShow(false)}>
          <CloseIcon />
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
