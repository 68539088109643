import React, { FC } from 'react';
import { Category } from 'shared/types/product/Category';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import useClassNames from 'helpers/hooks/useClassNames';

export interface Props {
  link: Category;
  categoriesNavigator?: Category[];
  onClick: () => void;
  hideHeaderMenu: () => void;
}

const MobileMenuNavButton: FC<Props> = ({ link, categoriesNavigator, onClick, hideHeaderMenu }) => {
  const linkClassNames = useClassNames([
    'flex justify-between w-full items-center h-[54px]',
    categoriesNavigator?.length === 0
      ? 'pb-24'
      : link?.categoryId === 'myAccount'
      ? 'pt-24 pb-18'
      : 'py-16 border-b border-neutral-2',
  ]);
  return (
    <div key={link.categoryId} className="w-full cursor-pointer">
      {link?.subCategories?.length > 0 ? (
        <div onClick={onClick} className={linkClassNames}>
          <Typography className="flex items-center text-18 text-neutral-4">{link.name}</Typography>
          <span className="h-32 items-center rounded-full bg-base-accent-2 p-4 text-neutral-4">
            <ChevronRightIcon />
          </span>
        </div>
      ) : (
        <div onClick={hideHeaderMenu} className={linkClassNames}>
          <Link link={link?.path}>
            <Typography className="flex items-center text-18 text-neutral-4">{link.name}</Typography>
          </Link>
        </div>
      )}
    </div>
  );
};

export default MobileMenuNavButton;
