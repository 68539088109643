import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { Link as CategoryLink } from 'components/commercetools-ui/organisms/header/types';
import useClassNames from 'helpers/hooks/useClassNames';
import Image, { ImageProps } from 'frontastic/lib/image';

type Props = {
  image: ImageProps;
  title: string;
  subtitle?: string;
  categories?: CategoryLink[];
  className?: string;
  handleCategoryClick?: () => void;
};

export const EmptyState: React.FC<Props> = ({
  image,
  title,
  subtitle,
  categories,
  handleCategoryClick,
  className = '',
}: Props) => {
  const titleClassNames = useClassNames(['py-24 text-center', className]);

  return (
    <div className={titleClassNames}>
      <div className="py-20 md:py-24">
        <Typography className="text-center text-20 leading-[25px]">{title}</Typography>
        <div className="hidden w-full justify-center md:flex">
          <div className="relative mt-32 h-80 w-80 px-10 text-center md:mt-40">
            <Image media={image?.media} fill style={{ objectFit: 'contain' }} alt={image?.title} />
          </div>
        </div>
      </div>
      <ul className="mt-55 flex flex-col items-center gap-y-20">
        <Typography as="h6" className="text-center">
          {subtitle}
        </Typography>
        {categories
          ? categories.map((category) => (
              <li key={category.name} onClick={handleCategoryClick} data-test="empty-list-link">
                <Link link={category.reference}>
                  <Button className="w-200 rounded-[4px] text-16" variant="secondary">
                    {category.name}
                  </Button>
                </Link>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};
