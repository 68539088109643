import React, { FC, useState } from 'react';
import { Account } from 'shared/types/account';
import { Category } from 'shared/types/product/Category';
import Button from 'components/commercetools-ui/atoms/button';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import HamburgerMenuIcon from 'components/icons/HamburgerMenuIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'types/reference';
import MobileMenu from './content/mobile-menu';
import MobileMenuFooter from './content/mobile-menu-footer';
import MobileMenuHeader from './content/mobile-menu-header';
import { Link, Tile } from '../../types';

export interface Props {
  links: Category[];
  account: Account | undefined;
  logout: () => Promise<void>;
  logoLink?: Reference;
  contentLinks?: Link[];
  tiles: Tile[];
}

const HeaderNavigationMobile: FC<Props> = ({ links, logoLink, contentLinks, account, logout, tiles }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const [categoriesNavigator, setCategoriesNavigator] = useState<Category[]>([]);
  const [showMenu, setShowMenu] = useState(false);

  const showHeaderMenu = () => {
    setShowMenu(true);
  };

  const hideHeaderMenu = () => {
    setShowMenu(false);
    setCategoriesNavigator([]);
  };

  const removeCategory = () => {
    setCategoriesNavigator((array) => array.slice(0, -1));
  };

  const insertCategory = (category: Category) => {
    setCategoriesNavigator((array) => [...array, category]);
  };

  return (
    <div className="flex xl:hidden">
      <Button
        variant="ghost"
        size="fit"
        onClick={showHeaderMenu}
        title={formatMessage({ id: 'header.menu.open', defaultMessage: 'Open side menu' })}
        className="mr-16"
      >
        <HamburgerMenuIcon />
      </Button>

      <Drawer
        isOpen={showMenu}
        direction="left"
        className="w-full border border-neutral-400 bg-neutral-100 md:w-3/5"
        onClose={hideHeaderMenu}
      >
        <MobileMenuHeader
          categories={categoriesNavigator}
          hideHeaderMenu={hideHeaderMenu}
          onArrowClick={removeCategory}
        />

        <MobileMenu
          links={links}
          hideHeaderMenu={hideHeaderMenu}
          categoriesNavigator={categoriesNavigator}
          insertCategory={insertCategory}
          tiles={tiles}
        />

        {categoriesNavigator.length <= 0 && (
          <MobileMenuFooter
            showMenu={showMenu}
            hideHeaderMenu={hideHeaderMenu}
            insertCategory={insertCategory}
            contentLinks={contentLinks}
            account={account}
            logout={logout}
          />
        )}
      </Drawer>
    </div>
  );
};

export default HeaderNavigationMobile;
