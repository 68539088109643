import React, { ComponentProps, FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import Tooltip from '../tooltip';
import Typography from '../typography';

export interface InputProps extends Omit<ComponentProps<'input'>, 'onChange' | 'key'> {
  label?: string;
  labelDesc?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'primary' | 'secondary';
  labelPosition?: 'top' | 'inline';
  innerRef?: MutableRefObject<HTMLInputElement>;
  error?: string;
  errorMessage?: string;
  isValid?: boolean;
  hideCheckIcon?: boolean;
  validation?: (valueToValidate: string) => boolean;
  renderEndIcon?: () => React.ReactNode;
  wrapperClassName?: string;
  validationSchema?: (value: string) => { isValid: boolean; error?: string };
  tooltip?: string;
}

const Input: FC<InputProps> = ({
  name,
  tooltip,
  label,
  labelDesc,
  onChange,
  onBlur,
  onFocus,
  variant = 'primary',
  labelPosition = 'top',
  className = '',
  innerRef,
  value,
  children,
  hideCheckIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid: isValidProp,
  wrapperClassName,
  validationSchema,
  errorMessage,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState('');
  const [visibleTooltip, setVisibleTooltip] = useState('');

  useEffect(() => {
    setError(errorMessage ?? '');
  }, [errorMessage]);

  useEffect(() => {
    if (validationSchema) {
      const validation = validationSchema?.(value as string);
      setIsValid(!!validation.isValid);
      setError(validation.error ?? 'Error');
    }
  }, [isTouched, validationSchema, value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    [onChange],
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      onFocus?.(e);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setIsTouched(true);
      onBlur?.(e);
    },
    [onBlur],
  );

  const bgClassName = useMemo(
    () =>
      ({
        primary: 'bg-neutral-5',
        secondary: 'bg-neutral-2',
      }[variant]),
    [variant],
  );

  const isInActiveState = useMemo(() => isFocused || !!value, [isFocused, value]);

  const labelClassName = useClassNames([
    labelPosition ? 'leading-loose' : 'leading-normal',
    labelPosition === 'top' ? 'text-16' : 'text-10',
    'font-medium',
    { ['text-neutral-4']: labelPosition === 'top' },
    { [isInActiveState && label ? 'opacity-1 scale-100' : 'scale-0 opacity-0']: labelPosition === 'inline' },
    {
      ['absolute top-[6px] left-[12px] font-medium block transition duration-150 ease-out']: labelPosition === 'inline',
    },
  ]);

  const labelContainerClassName = useClassNames([
    {
      'mb-8': labelPosition === 'top',
    },
  ]);

  const inputClassName = useClassNames([
    'h-40 focus:border-gray-500 focus:ring-0 w-full rounded-[45px] border border-neutral-2 px-20 py-24 text-neutral-4 placeholder:text-16 placeholder:leading-5 placeholder:text-neutral-3 focus:outline-none disabled:cursor-not-allowed disabled:border-none disabled:bg-base-accent-3',
    bgClassName,
    isInActiveState && label && labelPosition == 'inline' ? 'pt-[20px] pb-[4px]' : 'py-10',
    {
      'border-status-danger-border focus:border-status-danger-border': !isFocused && isTouched && !isValid,
    },
    { 'border-green-500 focus:border-green-500': isFocused },
    className,
  ]);

  return (
    <div className={`relative ${wrapperClassName}`}>
      <div className={`${labelContainerClassName} ${tooltip && name ? 'flex items-center gap-4' : ''}`}>
        {label && (
          <Typography as="label" className={labelClassName}>
            {props.required ? `${label} *` : label}
          </Typography>
        )}
        {labelDesc && (
          <Typography as="label" className={labelClassName}>
            {` (${labelDesc})`}
          </Typography>
        )}
        {tooltip && name ? (
          <Tooltip
            show={name === visibleTooltip}
            setShow={(show) => setVisibleTooltip(show ? name : '')}
            message={tooltip}
          />
        ) : null}
      </div>
      <div className="relative">
        <input
          name={name}
          className={inputClassName}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={innerRef}
          value={value}
          {...props}
        />
        {isTouched && isValid && !isFocused && !hideCheckIcon && (
          <CheckIcon className="absolute right-12 top-[50%] h-16 w-16 translate-y-[-50%] text-green-500" />
        )}
        {children}
      </div>
      {((isTouched && !isValid) || (errorMessage && error)) && (
        <div className="mt-12 flex items-center gap-4">
          <span className="text-status-danger-border">
            <ExclamationMarkIcon />
          </span>
          <Typography className="text-14 text-status-danger-border" as="span">
            {error}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Input;
