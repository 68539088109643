import { FC } from 'react';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Typography from 'components/commercetools-ui/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import LoginSuggestion from './components/login-suggestion';
import { OrderOverviewProps } from './types';
import Costs from '../order-payment-section/components/costs';
import DiscountForm from '../order-payment-section/components/discount-form';
import PaymentMethods from '../order-payment-section/components/payment-methods';

// Used in "Cart", "Checkout", "Drawer Cart" and "Drawer Checkout" pages
const OrderOverview: FC<OrderOverviewProps> = ({
  costsPropsFromParentPage,
  ctaButton,
  page,
  paymentMethods,
  title,
  regulationTextConfig = null,
  showLogin = false,
  modalLogin,
}) => {
  const [isDesktop] = useMediaQuery(desktop);

  const containerClassName = `bg-white lg:min-w-[30%] lg:w-[30%] ${
    page === 'drawer-cart' || 'cart' ? 'shadow-custom' : ''
  } ${page === 'cart' ? 'mt-24 lg:mt-60' : ''} ${page === 'drawer-cart' ? 'lg:w-full' : ''}`;
  const titleContainerClassName = `pt-32 px-20 ${showLogin ? 'pb-20' : 'bg-base-accent-3'}`;
  const titleTypographyClassName = 'text-20 font-bold';

  // Checkout mobile
  if (page === 'checkout' && !isDesktop) {
    return (
      <div className="px-16 pb-12 md:px-24 lg:px-0">
        <Accordion
          closedSectionTitle={title}
          buttonWrapperClassName="px-20 py-24 bg-base-accent-3"
          buttonClassName="font-bold text-18"
        >
          {/* Discount form */}
          <DiscountForm additionalWrapperClassName="!pt-6" />
          {/* Costs */}
          <Costs page={page} costsPropsFromParentPage={costsPropsFromParentPage} additionalWrapperClassName="!pb-20" />
          {/* Payment Methods */}
          <PaymentMethods paymentMethods={paymentMethods} />
        </Accordion>
      </div>
    );
  }

  // Cart mobile, Cart desktop, Checkout desktop, Drawer Cart desktop and Drawer Cart mobile
  return (
    <div className={containerClassName}>
      {/* Title and login box */}
      {page === 'drawer-cart' ? null : (
        <div className={titleContainerClassName}>
          <Typography className={titleTypographyClassName}>{title}</Typography>
          {showLogin && modalLogin ? <LoginSuggestion modalLogin={modalLogin} /> : null}
        </div>
      )}

      {/* Discount form */}
      {page === 'drawer-cart' ? null : <DiscountForm />}

      {/* Costs */}
      <Costs
        page={page}
        costsPropsFromParentPage={costsPropsFromParentPage}
        additionalWrapperClassName={page === 'drawer-cart' ? '!p-20' : ''}
      />

      {/* Regulation text */}
      {page === 'checkout' && regulationTextConfig?.regulationFinalText && (
        <label className="flex items-start gap-12 bg-base-accent-3 px-20 pb-20">
          <Checkbox
            checked={regulationTextConfig.checkbox.value}
            onChange={() => regulationTextConfig.checkbox.set(!regulationTextConfig.checkbox.value)}
          />
          {regulationTextConfig.regulationFinalText}
        </label>
      )}

      {/* CTA Button */}
      <div className="bg-base-accent-3 px-20 pb-32">{ctaButton}</div>

      {/* Payment Methods */}
      {paymentMethods ? <PaymentMethods paymentMethods={paymentMethods} /> : null}
    </div>
  );
};

export default OrderOverview;
