function CouponIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M11.0755 21.5C10.8495 21.5 10.623 21.4548 10.396 21.3645C10.1688 21.274 9.96442 21.1384 9.78275 20.9578L3.027 14.202C2.84367 14.0193 2.71 13.8176 2.626 13.5968C2.542 13.3761 2.5 13.1516 2.5 12.9233C2.5 12.6949 2.542 12.4672 2.626 12.24C2.71 12.013 2.84367 11.8087 3.027 11.627L11.5963 3.04225C11.7622 2.87658 11.9584 2.74483 12.1848 2.647C12.4111 2.549 12.645 2.5 12.8865 2.5H19.6672C20.1724 2.5 20.6016 2.67792 20.9548 3.03375C21.3079 3.38975 21.4845 3.81758 21.4845 4.31725V11.098C21.4845 11.342 21.4393 11.5745 21.349 11.7955C21.2587 12.0165 21.1283 12.209 20.9578 12.373L12.3635 20.9578C12.1827 21.1384 11.9792 21.274 11.7533 21.3645C11.5274 21.4548 11.3015 21.5 11.0755 21.5ZM10.852 19.9038C10.916 19.9679 10.9913 20 11.078 20C11.1645 20 11.2397 19.9679 11.3038 19.9038L19.898 11.3038C19.9302 11.2718 19.9527 11.2365 19.9655 11.198C19.9782 11.1597 19.9845 11.1213 19.9845 11.0828V4.31725C19.9845 4.22758 19.9541 4.15392 19.8932 4.09625C19.8324 4.03842 19.7571 4.0095 19.6672 4.0095H12.777L4.09625 12.6962C4.03208 12.7603 4 12.8355 4 12.922C4 13.0087 4.03208 13.084 4.09625 13.148L10.852 19.9038ZM17.4557 7.77875C17.8056 7.77875 18.103 7.65725 18.348 7.41425C18.5928 7.17125 18.7153 6.87608 18.7153 6.52875C18.7153 6.17892 18.5933 5.88158 18.3492 5.63675C18.1051 5.39175 17.8086 5.26925 17.4598 5.26925C17.1109 5.26925 16.8147 5.39133 16.5712 5.6355C16.3276 5.87967 16.2057 6.17617 16.2057 6.525C16.2057 6.87367 16.3273 7.16983 16.5703 7.4135C16.8134 7.657 17.1086 7.77875 17.4557 7.77875Z"
      />
    </svg>
  );
}

export default CouponIcon;
