import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { Category } from 'shared/types/product';
import { Product } from 'shared/types/product/Product';
import Image from 'frontastic/lib/image';
import Link from '../../link';

interface Props {
  hit: Product;
  categories: Category[];
  onClick?: () => void;
}

const SearchItem: React.FC<Props> = ({ hit, onClick, categories }) => {
  const router = useRouter();
  const variant = useMemo(() => hit.variants?.[0], [hit]);

  const primaryCategory = useMemo(
    () => categories.find((category) => category.categoryId === hit.categories?.[0]?.categoryId),
    [categories, hit],
  );

  const handleClick = useCallback(() => {
    onClick?.();
    router.push(hit._url ?? '#');
  }, [hit, onClick, router]);

  return (
    <Link link={hit._url} onMouseUp={handleClick}>
      <div className="flex gap-12">
        <div className="shrink-0 p-8">
          <div className="relative h-90 w-80">
            <Image cloudimage={true} src={variant?.images?.[0]} />
          </div>
        </div>
        <div className="pt-12">
          <p className="text-16 font-bold">{hit.name}</p>
          <div className="mt-5">
            <span className="text-14 leading-[21px] text-neutral-4">{primaryCategory?.name}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SearchItem;
