import { FC } from 'react';
import { useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { LoginContent } from '../../authentication/login';

export interface AlterFormProps {
  studio: LoginContent;
}

const AlterForm: FC<AlterFormProps> = ({ studio }) => {
  const router = useRouter();

  const handleRegister = () => {
    router.push('/register');
  };

  const handleActivation = () => {
    router.push('/activation');
  };

  return (
    <div className="flex flex-col gap-32">
      <Typography as="h3" className="text-24 font-bold leading-[30px] text-neutral-4">
        {studio.createAccountHeadline}
      </Typography>
      <div>
        <Typography className="mb-12 text-16 leading-[20px] text-neutral-4">{studio.registerAccountText}</Typography>
        <Button className="max-lg:w-full" onClick={handleRegister}>
          {studio.registerAccountButtonLabel}
        </Button>
      </div>
      <div>
        <Typography className="mb-12 text-16 leading-[20px] text-neutral-4">{studio.activateAccountText}</Typography>
        <Button className="max-lg:w-full" variant="secondary" onClick={handleActivation}>
          {studio.activateAccountButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default AlterForm;
