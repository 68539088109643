import React, { FC, ReactNode } from 'react';
import TruckIcon from '../TruckIcon';

type BaseProps = {
  variant: 'delivery' | 'promotion';
  children?: ReactNode;
};

type DiscountProps = {
  variant: 'discount' | 'discount-code';
  children: ReactNode;
};

type TextLabelProps = BaseProps | DiscountProps;

const TextLabel: FC<TextLabelProps> = ({ variant, children = null }) => {
  let variantClassName = '';
  if (variant === 'delivery') {
    variantClassName = 'bg-base-accent-1';
    if (!children) {
      children = (
        <>
          <TruckIcon scale={0.67} />
          24H
        </>
      );
    }
  }
  if (variant === 'promotion') {
    variantClassName = 'bg-base-brand-1';
    if (!children) {
      children = 'Promocja';
    }
  }
  if (variant === 'discount') {
    variantClassName = 'bg-neutral-3';
  }
  if (variant === 'discount-code') {
    variantClassName = 'bg-base-accent-1';
  }

  return (
    <div
      className={`flex items-center justify-center gap-4 rounded-full px-8 py-2 text-sm font-bold leading-[17.5px] text-neutral-5 ${variantClassName}`}
    >
      {children}
    </div>
  );
};

export default TextLabel;
