import { FC } from 'react';
import { Money } from 'shared/types/product';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

interface ProductSummaryPromotionalInfo {
  promotionalInfoText: string;
  additionalClassName?: string;
  amountClassName?: string;
  amount?: Money;
}

const PromotionalInfo: FC<ProductSummaryPromotionalInfo> = ({
  promotionalInfoText,
  additionalClassName,
  amount,
  amountClassName,
}) => {
  return (
    <div className={`mt-16 text-neutral-4 ${additionalClassName}`}>
      <p className="text-14 leading-[17.5px] ">
        {promotionalInfoText}{' '}
        {amount && (
          <span className={amountClassName ?? 'font-bold'}>{CurrencyHelpers.formatForCurrency(amount ?? '')} </span>
        )}
      </p>
    </div>
  );
};

export default PromotionalInfo;
