function ArrowRightIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M16.627 12.75H4.5v-1.5h12.127L10.93 5.554 12 4.5l7.5 7.5-7.5 7.5-1.07-1.054 5.697-5.696z"
      ></path>
    </svg>
  );
}

export default ArrowRightIcon;
