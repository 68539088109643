import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { Popover } from '@headlessui/react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';

const LoggedIn = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const router = useRouter();
  const { logout } = useAccount();
  const [logoutIsHandled, setLogoutIsHandled] = useState(false);

  useEffect(() => {
    if (logoutIsHandled) {
      router.push('/');
    }
  }, [logoutIsHandled, router]);

  const handleLogout = async () => {
    await logout();
    setLogoutIsHandled(true);
  };

  return (
    <div className="w-[235px] p-14">
      <Popover.Button className="w-full">
        <Link link="/account" className="mt-10 block w-fit">
          <Typography className="text-start text-neutral-4 hover:underline">
            {formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' })}
          </Typography>
        </Link>
      </Popover.Button>

      <Popover.Button className="w-full">
        <Link link="/account?hash=support" className="mt-32 block w-fit">
          <Typography className="text-start text-neutral-4 hover:underline">
            {formatAccountMessage({ id: 'customer.support', defaultMessage: 'Customer support' })}
          </Typography>
        </Link>
      </Popover.Button>

      <Popover.Button className="mt-32 block w-full pb-10">
        <div onClick={handleLogout} className="w-fit">
          <Typography className="text-start text-16 text-neutral-4 hover:underline">
            {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
          </Typography>
        </div>
      </Popover.Button>
    </div>
  );
};

export default LoggedIn;
