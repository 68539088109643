import { Cart } from 'shared/types/cart/Cart';
import { Order } from 'shared/types/cart/Order';
import { UseCartReturn } from 'frontastic/hooks/useCart/types';

type MapCosts = (props: {
  reference?: 'cart' | 'order';
  cart?: Cart;
  order?: Order;
  currency: string;
}) => UseCartReturn['transaction'];

const mapCosts: MapCosts = ({ reference = 'order', cart, order, currency }) => {
  const data = { ...(reference === 'cart' ? cart : order) } as Cart;

  if (!data?.lineItems?.length) {
    return {
      subtotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      net: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      discount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      tax: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      shipping: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      total: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
    };
  }

  const currencyCode = data.sum?.currencyCode ?? currency;
  const fractionDigits = data.sum?.fractionDigits ?? 2;

  const totalAmount = data.sum?.centAmount as number;

  const subTotalAmount = data.sumOfLineItemsGross?.centAmount as number;

  const discountedAmount = data.discountGross?.centAmount ?? 0;

  const totalTax = totalAmount > 0 ? data.taxed?.amount.centAmount ?? 0 : 0;

  const totalShipping = data?.shippingPriceGross?.centAmount ?? 0;

  const totalNet = data.sumOfLineItemsNet?.centAmount ?? 0;

  return {
    subtotal: {
      centAmount: subTotalAmount,
      currencyCode,
      fractionDigits,
    },
    net: {
      centAmount: totalNet,
      currencyCode,
      fractionDigits,
    },
    discount: {
      centAmount: discountedAmount,
      currencyCode,
      fractionDigits,
    },
    shipping: {
      centAmount: totalShipping,
      currencyCode,
      fractionDigits,
    },
    tax: {
      centAmount: totalTax,
      currencyCode,
      fractionDigits,
    },
    total: {
      centAmount: totalAmount,
      currencyCode,
      fractionDigits,
    },
  };
};

export default mapCosts;
