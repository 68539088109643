import React, { useEffect, useMemo, useState } from 'react';
import { Discount } from 'shared/types/cart';
import { LineItem } from 'shared/types/cart/LineItem';
import Button from 'components/commercetools-ui/atoms/button';
import CartButton from 'components/commercetools-ui/atoms/cart-button';
import useTrack from 'components/commercetools-ui/atoms/cart-button/useTrack';
import Typography from 'components/commercetools-ui/atoms/typography';
import TrashIcon from 'components/icons/TrashIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { md } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import { ProductLabels } from '../../product/product-tile';
import ProductTileLabels from '../../product/product-tile/components/product-tile-labels';
import PromotionalInfo from '../../product/product-tile/components/promotional-info';
import Prices from '../../product/product-tile/prices';

export interface Props {
  item: LineItem;
  discountCodes?: Discount[];
  variant?: 'mobile';
  page?: 'drawer-cart' | 'cart-page';
}

const CartItem: React.FC<Props> = ({ item, variant, page = 'cart-page', discountCodes }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { trackAddToCartLineItem } = useTrack();
  const { removeItem, updateItem } = useCart();
  const [quantity, setQuantity] = useState<number>(0);
  const [inputQuantity, setInputQuantity] = useState<number>(0);
  const [added, setAdded] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingRemoveItem, setLoadingRemoveItem] = useState(false);

  const disabledButtonClassName = loadingRemoveItem ? '!cursor-not-allowed !opacity-[0.3] !pointer-events-none' : '';

  useEffect(() => {
    if (item?.count) {
      setQuantity(item.count);
    } else {
      setQuantity(0);
    }
  }, [item]);

  const productLabels = useMemo(() => {
    return {
      isDelivery: item?.variant?.isOnStock ?? false,
      isMadeInPoland: item?.variant?.attributes?.madeInPoland?.value ?? false,
      isOnPromotion: !!(item?.variant?.strikeThroughPrice || item?.variant?.percentOfDiscount),
    } as ProductLabels;
  }, [item]);

  const handleRemoveItem = async () => {
    if (loadingRemoveItem) return;
    setLoadingRemoveItem(true);
    await removeItem(item.lineItemId as string);
    setLoadingRemoveItem(false);
  };

  const handleUpdateCartItem = async (newQuantity: number) => {
    if (loadingRemove) return;
    newQuantity > quantity ? setLoadingAdd(true) : setLoadingRemove(true);

    if (!item?.lineItemId) return;

    if (newQuantity < 1) {
      handleRemoveItem();
    } else {
      await updateItem(item.lineItemId as string, newQuantity);
    }
    if (newQuantity > quantity) {
      trackAddToCartLineItem(item, newQuantity);
      setLoadingAdd(false);
      setAdded(true);
    } else {
      setLoadingRemove(false);
      setRemoved(true);
    }
    setTimeout(() => {
      setAdded(false);
      setRemoved(false);
      setQuantity(newQuantity);
    }, 1500);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = +(e.target as HTMLInputElement).value;
    setInputQuantity(newQuantity);
  };

  const handleQuantityBlur = (e: React.FormEvent) => {
    const newQuantity = +(e.target as HTMLSelectElement).value;
    if (quantity === newQuantity) {
      return;
    }

    handleUpdateCartItem(newQuantity);
    setInputQuantity(0);
    quantity > newQuantity ? setLoadingRemove(true) : setLoadingAdd(true);

    const loadingTimer = setTimeout(() => {
      if (quantity > newQuantity) {
        setLoadingRemove(false);
        setRemoved(true);
      } else {
        setLoadingAdd(false);
        setAdded(true);
      }
      clearTimeout(loadingTimer);

      const addedTimer = setTimeout(() => {
        quantity > newQuantity ? setRemoved(false) : setAdded(false);
        clearTimeout(addedTimer);
      }, 500);
    }, 1500);
  };

  const [isAboveMd] = useMediaQuery(md);

  const isMobile = variant ? variant === 'mobile' : !isAboveMd;
  const isOnStock = item.variant?.isOnStock ?? false;

  const discount = item.discounts?.[0] ? discountCodes?.[0] : undefined;

  // If there's a discount on top of promotion, don't show promotion percent
  const percentOfDiscount = discount ? undefined : item.variant?.percentOfDiscount;

  const cartItemMobile = (
    <div className={`flex flex-col gap-20 bg-neutral-5 px-12 py-24 ${page === 'cart-page' ? 'shadow-custom' : ''}`}>
      <div className="mt-16 flex flex-row justify-between">
        <div className="flex flex-row gap-16">
          <div className="h-[60px] w-[60px] shrink-0 bg-white">
            <div
              className={`relative h-full w-full rounded-sm ${isOnStock ? '' : 'opacity-50'}`}
              data-test="responsive-image"
            >
              <Image cloudimage={true} src={item.variant?.images?.[0]} />
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <ProductTileLabels labels={productLabels} discount={discount} />
            <Typography
              className={`overflow-hidden text-ellipsis text-16 font-bold leading-5 ${
                isOnStock ? 'text-neutral-4' : 'text-neutral-2'
              }`}
              title={item.name}
              data-test="line-item-name"
            >
              {item.name}
            </Typography>
            {isOnStock ? null : (
              <Typography className="text-14 leading-[17.5px] text-base-brand-1">
                {formatProductMessage({ id: 'noStock', defaultMessage: 'This Product is not in stock' })}
              </Typography>
            )}
          </div>
        </div>

        <div data-test="remove-button">
          <Button variant="ghost" className="!p-0" onClick={handleRemoveItem} loading={loadingRemoveItem}>
            <TrashIcon />
          </Button>
        </div>
      </div>
      <div
        className={`flex flex-row ${
          item.variant?.percentOfDiscount && item.strikeThroughPrice ? 'items-end' : 'items-center'
        } justify-between ${isOnStock ? '' : 'ml-auto'}`}
      >
        {isOnStock ? (
          <div className={disabledButtonClassName}>
            <CartButton
              addToCart={() => handleUpdateCartItem(quantity + 1)}
              removeFromCart={() => handleUpdateCartItem(quantity - 1)}
              onChange={handleQuantityChange}
              onBlur={handleQuantityBlur}
              loadingAdd={loadingAdd}
              loadingRemove={loadingRemove}
              added={added}
              removed={removed}
              quantity={quantity}
              inputQuantity={inputQuantity}
            />
          </div>
        ) : null}
        <Prices
          price={item.taxedPrice}
          percentOfDiscount={percentOfDiscount}
          strikeThroughPrice={item.strikeThroughPrice}
          variant="cart-item"
          additionalClassName="!mt-0"
          disabled={!isOnStock}
        />
      </div>
    </div>
  );

  const cartItemDesktop = (
    <div
      className={`flex flex-col gap-4 bg-neutral-5 px-20 pb-48 pt-20 ${page === 'cart-page' ? 'shadow-custom' : null}`}
    >
      <div data-test="remove-button" className="self-end">
        <Button variant="ghost" className="!p-0" onClick={handleRemoveItem} loading={loadingRemoveItem}>
          <TrashIcon />
        </Button>
      </div>

      <div className="flex flex-row gap-40">
        <div className="flex flex-row items-center gap-16">
          <div className="h-[94px] w-[94px] shrink-0 bg-white">
            <div
              data-test="responsive-image"
              className={`relative h-full w-full rounded-sm ${isOnStock ? '' : 'opacity-50'}`}
            >
              <Image cloudimage={true} src={item.variant?.images?.[0]} />
            </div>
          </div>
          <div className="flex flex-col gap-12">
            <ProductTileLabels labels={productLabels} discount={discount} />
            <div className="flex flex-col gap-8">
              <Typography
                className={`overflow-hidden text-ellipsis text-16 font-bold leading-5 ${
                  isOnStock ? 'text-neutral-4' : 'text-neutral-2'
                }`}
                title={item.name}
                data-test="line-item-name"
              >
                {item.name}
              </Typography>
              {item.variant?.sku ? (
                <Typography className={`text-14 leading-[17.5px] ${isOnStock ? 'text-neutral-3' : 'text-neutral-2'}`}>
                  {`${formatCartMessage({ id: 'article.number', defaultMessage: 'Art. Nr:' })} ${item.variant.sku}`}
                </Typography>
              ) : null}
              {isOnStock ? null : (
                <Typography className="text-14 leading-[17.5px] text-base-brand-1">
                  {formatProductMessage({ id: 'noStock', defaultMessage: 'This Product is not in stock' })}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className="ml-auto flex flex-row items-center gap-20">
          {isOnStock ? (
            <div className={`${disabledButtonClassName} w-130`}>
              <CartButton
                addToCart={() => handleUpdateCartItem(quantity + 1)}
                removeFromCart={() => handleUpdateCartItem(quantity - 1)}
                onChange={handleQuantityChange}
                onBlur={handleQuantityBlur}
                loadingAdd={loadingAdd}
                loadingRemove={loadingRemove}
                added={added}
                removed={removed}
                quantity={quantity}
                inputQuantity={inputQuantity}
              />
            </div>
          ) : null}
          <div className="w-160">
            <Prices
              price={item.taxedPrice}
              percentOfDiscount={percentOfDiscount}
              strikeThroughPrice={item.strikeThroughPrice}
              variant="cart-item"
              vatText={`${item.variant?.attributes?.packageSize?.value} ${formatProductMessage({
                id: 'baseUnit',
                defaultMessage: 'szt.',
              })} ${formatProductMessage({
                id: 'including',
                defaultMessage: 'incl.',
              })} ${formatProductMessage({ id: 'vat', defaultMessage: 'VAT' })}`}
              additionalClassName="!mt-0"
              disabled={!isOnStock}
              taxRate={`${formatProductMessage({ id: 'vat', defaultMessage: 'VAT' })} ${item.taxRate} %`}
            />
            {item.variant?.bestPrice30 && item.strikeThroughPrice && isOnStock ? (
              <PromotionalInfo
                promotionalInfoText={formatProductMessage({
                  id: 'bestPrice30Text',
                  defaultMessage: 'Lowest price since 30 days prior to discount',
                })}
                amount={item.variant.bestPrice30}
                additionalClassName="!mt-8 !text-neutral-3"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );

  return isMobile ? cartItemMobile : cartItemDesktop;
};

export default CartItem;
