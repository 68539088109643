import { useCallback } from 'react';
import { PaymentMethod } from 'shared/types/cart/Payment';

const usePaymentMethods = () => {
  const getPaymentMethods = useCallback(() => {
    return [
      {
        type: 'przelewy24',
      },
      {
        type: 'cash_on_delivery',
      },
    ] as PaymentMethod[];
  }, []);

  return { getPaymentMethods };
};

export default usePaymentMethods;
