import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import CommercetoolsSearch from 'components/commercetools-ui/atoms/search';
import HeaderLogo from 'components/commercetools-ui/organisms/header/header-logo';
import HeaderNavigationDesktop from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-desktop';
import HeaderNavigationMobile from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-mobile';
import { HeaderProps } from 'components/commercetools-ui/organisms/header/types';
import UtilitySection from 'components/commercetools-ui/organisms/header/utility-section';

const Header: React.FC<HeaderProps> = ({
  navLinks,
  categories,
  emptyCartTitle,
  emptyCartSubtitle,
  emptyCartImage,
  emptyCartCategories,
  emptyWishlistTitle,
  emptyWishlistSubtitle,
  emptyWishlistImage,
  emptyWishlistCategories,
  contentLinks,
  account,
  logout,
  tiles,
}) => {
  return (
    <header className="relative h-fit w-full border-b-[1.5px] border-neutral-400 bg-white">
      <Container>
        <div className="flex w-full items-center justify-between px-16 md:px-24 lg:px-20 xl:px-46">
          <div className="xl:hidden">
            <HeaderNavigationMobile
              links={navLinks}
              contentLinks={contentLinks}
              account={account}
              logout={logout}
              tiles={tiles ?? []}
            />
          </div>

          <div className="ml-0 flex w-full justify-start xl:ml-0 xl:w-fit">
            <HeaderLogo imageClassName="items-center justify-between xl:[&>svg]:h-32 xl:[&>svg]:w-180 flex [&>svg]:h-28 w-161 xl:py-24 py-12 text-16 font-bold md:text-28" />
          </div>

          <div className="relative hidden w-full px-16 py-12 xl:block">
            <CommercetoolsSearch categories={categories} />
          </div>

          <UtilitySection
            emptyCartTitle={emptyCartTitle}
            emptyCartSubtitle={emptyCartSubtitle}
            emptyCartImage={emptyCartImage}
            emptyCartCategories={emptyCartCategories}
            emptyWishlistTitle={emptyWishlistTitle}
            emptyWishlistSubtitle={emptyWishlistSubtitle}
            emptyWishlistImage={emptyWishlistImage}
            emptyWishlistCategories={emptyWishlistCategories}
          />
        </div>
      </Container>

      <div className="relative block w-full px-16 py-8 md:px-24 lg:px-20 xl:hidden">
        <CommercetoolsSearch categories={categories} />
      </div>

      <div className="hidden xl:block">
        <HeaderNavigationDesktop links={navLinks} tiles={tiles ?? []} />
      </div>
    </header>
  );
};
export default Header;
