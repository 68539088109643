import React from 'react';
import LoginForm from './login-form';
import AlterForm from '../../account/account-atoms/alter-form';
import { ModalLogin } from '../../cart/types';

export interface LoginProps {
  onLogin?: () => void;
  studio: LoginContent | ModalLogin;
}

export interface LoginContent {
  loginHeadline?: string;
  emailFieldLabel?: string;
  passwordFieldLabel?: string;
  rememberMeLabel?: string;
  forgotPasswordText?: string;
  loginButtonLabel?: string;
  createAccountHeadline?: string;
  registerAccountText?: string;
  registerAccountButtonLabel?: string;
  activateAccountText?: string;
  activateAccountButtonLabel?: string;
  forgotPasswordHeadline?: string;
  passwordResetLinkButtonLabel?: string;
  backToLoginButtonLabel?: string;
}

const Login: React.FC<LoginProps> = ({ onLogin, studio }) => {
  return onLogin ? (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full border-b border-neutral-2 bg-neutral-5 pb-32 lg:border-b-0 lg:border-r lg:pb-0 lg:pr-40">
        <LoginForm onLogin={onLogin} studio={studio} />
      </div>
      <div className="w-full bg-neutral-5 pt-32 lg:pl-40 lg:pt-0">
        <AlterForm studio={studio} />
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-12 px-16 md:px-24 lg:flex-row lg:px-20 xl:px-46">
      <div className="shadow-custom w-full bg-neutral-5 px-12 py-24 lg:p-40">
        <LoginForm studio={studio} />
      </div>
      <div className="shadow-custom w-full bg-neutral-5 px-12 py-24 lg:p-40">
        <AlterForm studio={studio} />
      </div>
    </div>
  );
};

export default Login;
