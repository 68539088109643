function DocumentIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M8.25 17.75h7.5v-1.5h-7.5v1.5zm0-4h7.5v-1.5h-7.5v1.5zM6.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h7.942l5.25 5.25v11.942c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H6.308zm7.192-13V4H6.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v15.384c0 .077.032.148.096.212a.294.294 0 00.212.096h11.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V8.5h-4.5z"
      ></path>
    </svg>
  );
}

export default DocumentIcon;
