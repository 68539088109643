import React, { useCallback, useEffect, useState } from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import LockIcon from 'components/icons/LockIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'types/reference';

export type AnnouncementVariant = 'default' | 'checkout';
export interface Props {
  text: string;
  highlightedSubstring: string;
  target?: Reference;
  variant?: AnnouncementVariant;
}

const AnnouncementBar: React.FC<Props> = ({ text, highlightedSubstring, target, variant = 'default' }) => {
  const [previewText, setPreviewText] = useState({ prefix: text, middle: '', suffix: '' });

  const processHighlighting = useCallback(() => {
    const start = text?.indexOf(highlightedSubstring);

    if (!highlightedSubstring || start === -1) {
      setPreviewText({ prefix: text, middle: '', suffix: '' });
      return;
    }

    const end = start + highlightedSubstring.length - 1;

    setPreviewText({
      prefix: text.substring(0, start),
      middle: text.substring(start, end + 1),
      suffix: text.substring(end + 1),
    });
  }, [text, highlightedSubstring]);

  useEffect(() => {
    processHighlighting();
  }, [processHighlighting]);

  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  return (
    <div className="relative h-32 w-full bg-base-accent-1 md:h-40 lg:h-44">
      <Container>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <p
            className={`flex flex-nowrap whitespace-nowrap text-center text-12  text-neutral-5 ${
              variant === 'checkout' ? 'font-bold ' : 'leading-loose'
            }`}
          >
            {variant === 'checkout' ? (
              <div className="mr-4">
                <LockIcon scale={0.67} />
              </div>
            ) : null}
            {variant === 'checkout' &&
              !previewText.prefix &&
              formatCheckoutMessage({ id: 'announcement.bar', defaultMessage: 'You are in a safe shopping zone' })}
            {previewText.prefix}
            <Link link={target} className="pl-4 underline underline-offset-2">
              {previewText.middle}
            </Link>
            {previewText.suffix}
          </p>
        </div>
      </Container>
    </div>
  );
};
export default AnnouncementBar;
