function TrashIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7.308 20.5c-.499 0-.925-.177-1.278-.53a1.742 1.742 0 01-.53-1.278V6h-1V4.5H9v-.885h6V4.5h4.5V6h-1v12.692c0 .506-.175.933-.525 1.283-.35.35-.778.525-1.283.525H7.308zM17 6H7v12.692a.3.3 0 00.087.221.3.3 0 00.22.087h9.385a.294.294 0 00.212-.096.294.294 0 00.096-.212V6zM9.404 17h1.5V8h-1.5v9zm3.692 0h1.5V8h-1.5v9z"
      ></path>
    </svg>
  );
}

export default TrashIcon;
