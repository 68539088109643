import React, { FC, useEffect, useState } from 'react';
import { Category } from 'shared/types/product/Category';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';

export interface Props {
  show: boolean;
  links: Category[];
}

const MegaMenu: FC<Props> = ({ show, links }) => {
  const [activeCategory, setActiveCategory] = useState<Category>();
  const [loading, setLoading] = useState<string | undefined>(undefined);

  // Initialize menu with first category active
  useEffect(() => {
    if (show) {
      setActiveCategory(links[0]);
    }
  }, [links, show]);

  const handleMouseIn = (category: Category) => {
    setActiveCategory(category);
  };

  const wrapperClassNames = useClassNames([
    'transition absolute flex justify-between bottom-0 left-0 duration-300 ease-in-out min-h-[300px] w-[100%] translate-y-full',
    show ? 'opacity-1 z-20 delay-300' : 'opacity-0 z-[-999] pointer-events-none',
    'border-b-2 border-t-2 border-t-neutral-400 bg-white',
  ]);

  return (
    <div className={wrapperClassNames}>
      <Container>
        <div id="mega-menu-container" className="flex h-[calc(100vh_-_174px)] w-[1440px]">
          {/* Left side */}
          <div className="z-10 overflow-y-auto overscroll-contain bg-base-accent-3 py-32 pl-46 shadow-mega-menu">
            {links.map((link) => {
              const activeLink = link.name === activeCategory?.name;
              const activeCategoryWrapperClassName = `${activeLink ? 'bg-base-accent-1' : ''}`;
              const activeCategoryTypographyClassName = `${activeLink ? 'text-neutral-5' : ''}`;
              return (
                <React.Fragment key={link.categoryId}>
                  {link.depth === 1 && (
                    <div
                      className={`py-8 pl-16 pr-32 ${activeCategoryWrapperClassName}`}
                      onMouseEnter={() => handleMouseIn(link)}
                    >
                      <Link
                        onClick={() => setLoading(link.categoryId)}
                        prefetch={false}
                        link={link?.path}
                        variant="menu-header"
                        className="flex gap-8 whitespace-nowrap"
                      >
                        <Typography className={`text-14 font-bold ${activeCategoryTypographyClassName}`}>
                          {link.name}
                        </Typography>
                        {loading === link.categoryId && <LoadingIcon className="fill-white" />}
                      </Link>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>

          {/* Right side */}
          <div className="flex-1 overflow-y-auto overscroll-y-contain bg-neutral-5 p-40">
            <div className="masonry">
              {activeCategory &&
                activeCategory.subCategories.map((field) => {
                  const leafCategories = field.subCategories;
                  const hasLeafCategories = leafCategories.length > 0;

                  return (
                    // 2nd level category
                    <div key={field.categoryId} className="mt-32 break-inside-avoid first:mt-0">
                      <div className="pb-8">
                        <Link
                          onClick={() => setLoading(field.categoryId)}
                          prefetch={false}
                          link={field.path}
                          variant="menu-item"
                          className="flex gap-8 whitespace-nowrap"
                        >
                          <Typography className="text-14 font-bold">{field.name}</Typography>
                          {loading === field.categoryId && <LoadingIcon className="fill-gray-700" />}
                        </Link>
                      </div>

                      {/* 3rd level categories */}
                      {hasLeafCategories && (
                        <div className="flex flex-col gap-8 border-t border-t-base-accent-1 pt-8">
                          {leafCategories.map((leaf) => {
                            return (
                              <div key={leaf.categoryId}>
                                <Link
                                  onClick={() => setLoading(leaf.categoryId)}
                                  prefetch={false}
                                  link={leaf.path}
                                  variant="menu-item"
                                  className="flex gap-8 whitespace-nowrap"
                                >
                                  <Typography className="text-14">{leaf.name}</Typography>
                                  {loading === leaf.categoryId && <LoadingIcon className="fill-gray-700" />}
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MegaMenu;
