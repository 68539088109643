import React, { useMemo } from 'react';
import Cart from 'components/commercetools-ui/organisms/cart-slideout';
import { Link } from 'components/commercetools-ui/organisms/header/types';
import Wishlist from 'components/commercetools-ui/organisms/wishlist';
import CartIcon from 'components/icons/CartIcon';
import CloseIcon from 'components/icons/CloseIcon';
import HeartIcon from 'components/icons/HeartIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useWishlist } from 'frontastic';
import { ImageProps } from 'frontastic/lib/image';

export type MenuState = 'wishlist' | 'cart';

export interface SlideOutProps {
  state?: MenuState;
  changeState?: (newState?: MenuState) => void;
  onClose?: () => void;
  emptyCartTitle: string;
  emptyCartSubtitle: string;
  emptyCartImage: ImageProps;
  emptyCartCategories: Link[];
  emptyWishlistTitle: string;
  emptyWishlistSubtitle: string;
  emptyWishlistImage: ImageProps;
  emptyWishlistCategories: Link[];
  enableCartState?: boolean;
  enableWishlistState?: boolean;
}

const Slideout: React.FC<SlideOutProps> = ({
  state,
  changeState,
  onClose,
  emptyCartTitle,
  emptyCartImage,
  emptyWishlistTitle,
  emptyWishlistSubtitle,
  emptyWishlistImage,
  emptyWishlistCategories,
  enableCartState = true,
  enableWishlistState = true,
}) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  const { totalItems: totalCartItems } = useCart();

  const { totalItems: totalWishlistItems } = useWishlist();

  const title = useMemo(() => {
    switch (state) {
      case 'cart':
        return formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' });
      case 'wishlist':
        return formatWishlistMessage({ id: 'my.wishlist', defaultMessage: 'My Wishlist' });
    }
  }, [formatCartMessage, formatWishlistMessage, state]);

  const iconClassName = 'absolute -bottom-5 left-1/2 -translate-x-1/2 h-2 w-[130%] transition duration-200';

  const wishlistClassName = useClassNames([
    iconClassName,
    state === 'wishlist' ? 'bg-base-accent-1 ease-out' : 'bg-transparent ease-in',
  ]);

  const cartClassName = useClassNames([
    iconClassName,
    state === 'cart' ? 'bg-base-accent-1 ease-out' : 'bg-transparent ease-in',
  ]);

  const ActiveSection = useMemo(
    () =>
      ({
        cart: <Cart emptyStateImage={emptyCartImage} emptyStateTitle={emptyCartTitle} />,
        wishlist: (
          <Wishlist
            emptyWishlistTitle={emptyWishlistTitle}
            emptyWishlistSubtitle={emptyWishlistSubtitle}
            emptyWishlistImage={emptyWishlistImage}
            emptyWishlistCategories={emptyWishlistCategories}
            handleCategoryClick={onClose}
          />
        ),
      }[state as MenuState] ?? <></>),
    [
      emptyCartImage,
      emptyCartTitle,
      emptyWishlistCategories,
      emptyWishlistImage,
      emptyWishlistSubtitle,
      emptyWishlistTitle,
      onClose,
      state,
    ],
  );

  return (
    <>
      <div className="flex items-center justify-between border-b border-neutral-400 px-20 pb-4 pt-24 ">
        {title ? <h3 className="text-20 font-bold leading-normal">{title}</h3> : null}
        <div className="flex h-full items-center gap-24">
          {enableWishlistState && (
            <div
              className="relative h-full cursor-pointer transition hover:opacity-80"
              onClick={() => changeState?.('wishlist')}
            >
              <div className={wishlistClassName} />
              {totalWishlistItems > 0 && (
                <span className="absolute right-[-8px] top-0 h-8 w-8 rounded-full bg-base-accent-1" />
              )}
              <HeartIcon scale={1.17} />
            </div>
          )}
          {enableCartState && (
            <div
              className="relative h-full cursor-pointer transition hover:opacity-80"
              onClick={() => changeState?.('cart')}
            >
              <>
                <div className={cartClassName} />
                <div className="relative">
                  <CartIcon scale={1.17} />
                  {totalCartItems > 0 && (
                    <span className="absolute right-[-8px] top-0  h-8 w-8 rounded-full bg-base-brand-1" />
                  )}
                </div>
              </>
            </div>
          )}
          <div onClick={onClose} className="cursor-pointer transition hover:opacity-80">
            <CloseIcon scale={1.17} />
          </div>
        </div>
      </div>
      {ActiveSection}
    </>
  );
};

export default Slideout;
