function Below744({}) {
  return (
    <svg
      preserveAspectRatio="none"
      width="100%"
      height="228"
      viewBox="0 0 743 228"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M743 0H0V211.832C44.4677 223.886 101.984 229.61 175.358 227.608C197.248 227.01 221.399 226.632 247.24 226.227C392.854 223.945 592.133 220.823 743 172.942V0Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Below744;
