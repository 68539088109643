function QuestionThinIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12.028 17.23a.759.759 0 00.56-.228.764.764 0 00.227-.56.759.759 0 00-.229-.56.764.764 0 00-.56-.228.76.76 0 00-.56.229.764.764 0 00-.227.56c0 .221.076.408.228.56.153.152.34.228.561.228zm-.517-3.31h.966c.026-.435.107-.789.245-1.06.138-.272.422-.619.851-1.04.446-.447.778-.847.996-1.2.218-.353.327-.762.327-1.226 0-.788-.277-1.416-.83-1.884-.554-.468-1.21-.702-1.966-.702-.72 0-1.33.195-1.832.586-.502.391-.87.84-1.103 1.348l.92.381c.16-.364.389-.686.687-.965.298-.28.728-.42 1.29-.42.648 0 1.122.178 1.42.534.3.356.449.747.449 1.174a1.6 1.6 0 01-.281.928 4.91 4.91 0 01-.73.822c-.58.535-.96.988-1.14 1.36-.179.372-.268.826-.268 1.363zm.492 7.08a8.772 8.772 0 01-3.51-.709 9.094 9.094 0 01-2.859-1.922 9.085 9.085 0 01-1.925-2.857A8.753 8.753 0 013 12.003c0-1.244.236-2.414.708-3.51a9.094 9.094 0 011.924-2.859 9.085 9.085 0 012.856-1.925A8.753 8.753 0 0111.997 3c1.244 0 2.414.236 3.51.708a9.094 9.094 0 012.859 1.924 9.083 9.083 0 011.925 2.856A8.753 8.753 0 0121 11.997a8.772 8.772 0 01-.709 3.51 9.093 9.093 0 01-1.922 2.859 9.085 9.085 0 01-2.857 1.925 8.754 8.754 0 01-3.509.709zM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20z"
      ></path>
    </svg>
  );
}

export default QuestionThinIcon;
