import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Slider from 'components/commercetools-ui/atoms/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import Hero, { HeroProps } from '../hero';

export interface HeroSliderProps {
  autoplay: boolean;
  delay: string;
  slides: HeroProps[];
}

const HeroSlider: React.FC<HeroSliderProps> = ({ autoplay, delay, slides }) => {
  const [isTabletSize] = useMediaQuery(tablet);

  return (
    <Container>
      <div className="h-[390px] md:h-[320px] lg:h-[420px]">
        <Slider
          variation="hero-slider"
          bottomNavigation
          slidesPerView={1}
          arrows={isTabletSize}
          loop={true}
          autoplay={
            autoplay
              ? {
                  delay: 1000 * Number(delay),
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }
              : false
          }
        >
          {slides.map((slide, index) => (
            <Hero
              key={index}
              image={slide.image}
              title={slide.title}
              subtitle={slide.subtitle}
              ctaLabel={slide.ctaLabel}
              ctaReference={slide.ctaReference}
              backgroundColor={slide.backgroundColor}
              clickable={true}
            />
          ))}
        </Slider>
      </div>
    </Container>
  );
};

export default HeroSlider;
