import { useCallback } from 'react';
import { LineItem } from 'shared/types/cart';
import { Product } from 'shared/types/product';
import { ADD_TO_CART } from 'helpers/constants/events';
import { lineItemToGoogleAnalytics4Item, productToGoogleAnalytics4Item } from 'helpers/utils/mapGoogleAnalytics';

const useTrack = () => {
  const trackAddToCartProduct = useCallback(async (product: Product, quantity: number) => {
    const googleAnalytics4Item = productToGoogleAnalytics4Item(product);
    const currency = product?.variants?.[0]?.price?.currencyCode ?? 'PLN';
    const value = Number((quantity * googleAnalytics4Item.price).toFixed(2));

    gtag('event', ADD_TO_CART, { currency, value, items: [{ ...googleAnalytics4Item, quantity }] });
  }, []);

  const trackAddToCartLineItem = useCallback(async (lineItem: LineItem, quantity: number) => {
    const googleAnalytics4Item = lineItemToGoogleAnalytics4Item(lineItem);
    const currency = lineItem?.price?.currencyCode ?? 'PLN';
    const value = Number((quantity * googleAnalytics4Item.price).toFixed(2));

    gtag('event', ADD_TO_CART, { currency, value, items: [{ ...googleAnalytics4Item, quantity }] });
  }, []);

  return { trackAddToCartProduct, trackAddToCartLineItem };
};

export default useTrack;
