import { FC, MouseEvent } from 'react';
import dynamic from 'next/dynamic';
import Button from 'components/commercetools-ui/atoms/button';
import { EnergyLabelType } from '../index';

const EnergyA3PlusLabel = dynamic(() => import('components/icons/labels/EnergyA3PlusLabel'));
const EnergyA2PlusLabel = dynamic(() => import('components/icons/labels/EnergyA2PlusLabel'));
const EnergyAPlusLabel = dynamic(() => import('components/icons/labels/EnergyAPlusLabel'));
const EnergyALabel = dynamic(() => import('components/icons/labels/EnergyALabel'));
const EnergyBLabel = dynamic(() => import('components/icons/labels/EnergyBLabel'));
const EnergyCLabel = dynamic(() => import('components/icons/labels/EnergyCLabel'));
const EnergyDLabel = dynamic(() => import('components/icons/labels/EnergyDLabel'));
const EnergyELabel = dynamic(() => import('components/icons/labels/EnergyELabel'));
const EnergyFLabel = dynamic(() => import('components/icons/labels/EnergyFLabel'));
const EnergyGLabel = dynamic(() => import('components/icons/labels/EnergyGLabel'));

export type EnergyLabelAttributes = {
  energyType: EnergyLabelType;
  energyURL: string;
};
export interface EnergyLabelProps {
  energyLabel: EnergyLabelAttributes;
  className?: string;
}

const EnergyLabel: FC<EnergyLabelProps> = ({ energyLabel, className }) => {
  let label;
  switch (energyLabel.energyType) {
    case 'A+++':
      label = <EnergyA3PlusLabel />;
      break;
    case 'A++':
      label = <EnergyA2PlusLabel />;
      break;
    case 'A+':
      label = <EnergyAPlusLabel />;
      break;
    case 'A':
      label = <EnergyALabel />;
      break;
    case 'B':
      label = <EnergyBLabel />;
      break;
    case 'C':
      label = <EnergyCLabel />;
      break;
    case 'D':
      label = <EnergyDLabel />;
      break;
    case 'E':
      label = <EnergyELabel />;
      break;
    case 'F':
      label = <EnergyFLabel />;
      break;
    case 'G':
      label = <EnergyGLabel />;
      break;
    default:
      label = energyLabel.energyType === 'Nie dotyczy' ? '' : energyLabel.energyType;
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(energyLabel.energyURL, '_blank', 'noopener noreferrer');
  };

  return (
    <div className={`absolute bottom-8 left-0 sm:left-0 sm:top-8 ${className}`}>
      <Button variant="ghost" className="!p-0" onClick={handleClick}>
        <span>{label}</span>
      </Button>
    </div>
  );
};

export default EnergyLabel;
