import { FC, useState } from 'react';
import EyeIcon from 'components/icons/EyeIcon';
import EyeOff from 'components/icons/EyeOffIcon';
import Input, { InputProps } from '../input';

const PasswordInput: FC<InputProps> = (props) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [type, setType] = useState<'text' | 'password'>('password');

  const toggleHidden = () => {
    setHidden(!hidden);
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <div className="relative">
      <Input type={type} hideCheckIcon {...props}>
        <span
          className="absolute right-26 top-26 z-10 translate-y-[-50%] text-neutral-3 hover:cursor-pointer"
          onClick={toggleHidden}
        >
          {hidden ? <EyeIcon /> : <EyeOff />}
        </span>
      </Input>
    </div>
  );
};

export default PasswordInput;
