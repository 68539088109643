import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Category } from 'shared/types/product/Category';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import HeaderNavigationButtonDesktop from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-desktop/header-navigation-button';
import { useProduct } from 'frontastic';
import MegaMenu from './mega-menu';
import { Tile } from '../../types';

export interface Props {
  links: Category[];
  tiles: Tile[];
}

const HeaderNavigationDesktop: React.FC<Props> = ({ links, tiles }) => {
  const [activeCategory, setActiveCategory] = useState<Category>();
  const { categoriesLoading } = useProduct();
  const showSubMenu = (category: Category) => setActiveCategory(category);
  const hideSubMenu = () => setActiveCategory(undefined);

  return (
    <div>
      <Container>
        <div className="flex">
          {categoriesLoading ? (
            <div className="flex h-52 items-center gap-46 pl-46">
              {[1, 2, 3, 4].map((i) => (
                <Skeleton key={i} height={24} width={110} />
              ))}
            </div>
          ) : (
            <>
              {links && (
                <div className="ml-46 hidden h-52 w-fit items-center xl:flex xl:gap-40" onMouseLeave={hideSubMenu}>
                  <div className="flex w-fit justify-start gap-40">
                    {links.map((category) => (
                      <div key={category?.categoryId} onMouseEnter={() => showSubMenu(category)}>
                        <HeaderNavigationButtonDesktop
                          show={category.categoryId === activeCategory?.categoryId}
                          link={category}
                          updateSubMenu={hideSubMenu}
                        />
                      </div>
                    ))}

                    {/* Mega-menu panel */}
                    <MegaMenu
                      show={!!activeCategory && activeCategory.subCategories.length > 0}
                      links={activeCategory?.subCategories ?? []}
                    />
                  </div>
                </div>
              )}
              <div className="flex">
                {tiles.map((tile) => (
                  <Link
                    className="ml-46 hidden h-52 w-fit items-center xl:flex xl:gap-40"
                    key={tile.tileCategory}
                    link={tile.tileButtonLink}
                  >
                    <span className="border-primary-black hover:border-b-2">{tile.tileCategory}</span>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default HeaderNavigationDesktop;
