import { FC } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import { PaymentMethodsProps } from '../types';

const PaymentMethods: FC<PaymentMethodsProps> = ({ paymentMethods }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  if (!paymentMethods || paymentMethods.length < 1) return;

  return (
    <div className="bg-white p-20 pb-32">
      <p className="text-18 font-bold lg:text-20">
        {formatCartMessage({ id: 'we.accept', defaultMessage: 'We accept' })}
      </p>
      <div className="mt-12 flex flex-wrap items-center gap-20">
        {paymentMethods.map(({ name, image }) => (
          <div key={name} className="h-32">
            <Image {...image} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;
