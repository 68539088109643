import React, { FC } from 'react';
import { useParams } from 'next/navigation';
import Typography from 'components/commercetools-ui/atoms/typography';
import TruckIcon from 'components/icons/TruckIcon';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import useCostsData from '../hooks/useCostsData';
import { CostsProps } from '../types';

const Costs: FC<CostsProps> = ({
  additionalWrapperClassName = '',
  costsPropsFromParentPage,
  dataReference = 'cart',
  order,
  page,
}) => {
  const { data } = useCart();
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const { locale: localeFromHook } = useParams();
  const {
    loading: loadingFromHook,
    costsToRender: costsToRenderFromHook,
    total: totalFromHook,
  } = useCostsData({ dataReference, order });

  // Allow receiving data from the parent page or from internal hooks
  const locale = costsPropsFromParentPage?.locale ?? localeFromHook;
  const loading = costsPropsFromParentPage?.loading ?? loadingFromHook;
  const costsToRender = costsPropsFromParentPage?.costsToRender ?? costsToRenderFromHook;
  const total = costsPropsFromParentPage?.total ?? totalFromHook;

  // If the cart is empty, the hook useCostsData will return skeletonCosts and have loading = true
  // In this case we follow the behavior from "The Good Store" and hide all costsToRender but show Total
  // The cart is empty if there are no items in it
  const isEmpty = useCart().isEmpty;

  // Progress bar values
  const subtotalCurrencyCode = costsToRender.find((el) => el.key === 'subtotal')?.value.currencyCode || 'PLN';

  /*
  - value == undefined: means that there is no shipping and we don't show the progress bar
  - value <= 0: means that the free shipping is applied and the progress bar is full (100%)
  - value > 0: means the remaining amount to get the free Shipping, and we show the progress bar
  */
  let remainingCentAmount = costsPropsFromParentPage?.freeShipping?.remainingCentAmount ?? undefined;
  if (remainingCentAmount && remainingCentAmount <= 0) {
    remainingCentAmount = 0;
  }

  const progressBarWidth = `${costsPropsFromParentPage?.freeShipping?.percentage}%`; // Between [0, 100]
  const freeShippingApplied =
    remainingCentAmount === 0 || costsToRender.find((el) => el.key === 'shipping')?.value.centAmount === 0;

  // Styles
  const wrapperClassName = 'bg-base-accent-3 px-20 py-32' + ' ' + additionalWrapperClassName;

  return (
    <div className={wrapperClassName}>
      <div className="grid gap-24">
        {/* Subcosts */}
        {!isEmpty &&
          costsToRender.map(({ key, label, value }) => {
            if ((value?.centAmount && value.centAmount > 0) || (key === 'shipping' && value.centAmount === 0)) {
              return (
                <div key={key} className="flex items-center justify-between">
                  <Typography asSkeleton={loading}>{label}</Typography>
                  <Typography asSkeleton={loading}>
                    {key === 'discount'
                      ? `- ${CurrencyHelpers.formatForCurrency(value, locale)}`
                      : CurrencyHelpers.formatForCurrency(value, locale)}
                  </Typography>
                </div>
              );
            } else {
              return <React.Fragment key={key}></React.Fragment>;
            }
          })}
        {!isEmpty && data?.paymentFee ? (
          <div key="paymentFee" className="flex items-center justify-between">
            <Typography asSkeleton={loading}>
              {formatPaymentMessage({
                id: 'payment.method.cod',
                defaultMessage: 'Cash on delivery',
              })}
            </Typography>
            <Typography asSkeleton={loading}>{CurrencyHelpers.formatForCurrency(data.paymentFee, locale)}</Typography>
          </div>
        ) : null}
      </div>

      {/* Progress bar for free shipping */}
      {/* TODO: Consider extracting this into a <FreeShipping> component during User Story "Order Summary integration" */}
      {(page === 'cart' || page === 'drawer-cart') && !loading && !isEmpty && (
        <div className="pt-24">
          {freeShippingApplied && (
            <>
              <div className="flex items-center gap-12">
                <span className="text-base-accent-1">
                  <TruckIcon />
                </span>
                <div>
                  <p className="font-bold">
                    {formatCartMessage({
                      id: 'free.shipping.applied.title',
                      defaultMessage: 'Congratulations!',
                    })}
                  </p>
                  <p>
                    {formatCartMessage({
                      id: 'free.shipping.applied.description',
                      defaultMessage: 'You have reached Free Shipping',
                    })}
                  </p>
                </div>
              </div>
            </>
          )}
          {!freeShippingApplied && remainingCentAmount && (
            <>
              <div className="flex items-center gap-12">
                <TruckIcon />
                <span>
                  {formatCartMessage({
                    id: 'free.shipping.remaining',
                    defaultMessage: 'Add {remaining} more to get Free Shipping',
                    values: {
                      remaining: CurrencyHelpers.formatForCurrency(
                        remainingCentAmount,
                        locale,
                        subtotalCurrencyCode,
                        2,
                      ),
                    },
                  })}
                </span>
              </div>
              <div className="relative mt-8">
                <div className="h-12 w-full rounded-full bg-white"></div>
                <div
                  className="absolute top-0 h-12 rounded-full bg-neutral-4"
                  style={{ width: progressBarWidth }}
                ></div>
              </div>
            </>
          )}
        </div>
      )}

      {/* Total */}
      <div
        className={`${isEmpty ? '' : 'mt-24'} flex items-baseline justify-between border-t border-t-neutral-4 pt-16`}
      >
        <Typography asSkeleton={loading && !isEmpty}>{total.label}</Typography>
        <Typography
          data-test={page === 'cart' ? 'cart-page-order-summary-total-price' : null}
          className="text-20 font-bold"
          asSkeleton={loading && !isEmpty}
        >
          {CurrencyHelpers.formatForCurrency(total.value, locale)}
        </Typography>
      </div>
    </div>
  );
};

export default Costs;
