function HeartIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M11.99 19.71c-.214 0-.429-.039-.645-.116a1.518 1.518 0 01-.57-.361l-1.437-1.306a65.858 65.858 0 01-4.749-4.765C3.196 11.602 2.5 9.932 2.5 8.15c0-1.42.479-2.607 1.436-3.565C4.893 3.628 6.08 3.15 7.5 3.15c.806 0 1.603.186 2.39.558.786.371 1.49.975 2.11 1.811.62-.836 1.324-1.44 2.11-1.811a5.541 5.541 0 012.39-.558c1.42 0 2.607.478 3.564 1.435.957.958 1.436 2.146 1.436 3.565 0 1.801-.708 3.49-2.125 5.068a63.781 63.781 0 01-4.739 4.724l-1.42 1.29a1.512 1.512 0 01-.576.362c-.219.077-.436.115-.65.115zm-.71-12.672c-.54-.824-1.11-1.428-1.708-1.812-.598-.384-1.289-.576-2.072-.576-1 0-1.833.333-2.5 1s-1 1.5-1 2.5c0 .802.259 1.642.776 2.517a16.267 16.267 0 001.948 2.612c.781.865 1.628 1.71 2.54 2.536.91.826 1.756 1.593 2.534 2.302a.294.294 0 00.202.077.294.294 0 00.202-.077c.778-.709 1.623-1.476 2.535-2.302a35.77 35.77 0 002.539-2.536 16.268 16.268 0 001.948-2.612c.517-.875.776-1.715.776-2.517 0-1-.333-1.833-1-2.5s-1.5-1-2.5-1c-.783 0-1.474.192-2.072.576-.598.384-1.168.988-1.709 1.812a.818.818 0 01-.319.289.884.884 0 01-.8 0 .818.818 0 01-.32-.289z"
      ></path>
    </svg>
  );
}

export default HeartIcon;
