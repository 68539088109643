import { FC } from 'react';
import { Discount } from 'shared/types/cart';
import { DiscountLabel } from 'components/commercetools-ui/atoms/discountLabel';
import GlutenFreeLabel from 'components/icons/labels/GlutenFreeLabel';
import MadeInPolandRectangleLabel from 'components/icons/labels/MadeInPolandRectangleLabel';
import TextLabel from 'components/icons/labels/TextLabel';
import { ProductLabels } from '../index';

export interface ProductTileLabelsProps {
  labels?: ProductLabels;
  discount?: Discount;
}

const ProductTileLabels: FC<ProductTileLabelsProps> = ({ labels, discount }) => {
  return (
    <div className="flex flex-wrap gap-x-8 gap-y-4">
      {labels?.isOnPromotion && <TextLabel variant="promotion" />}
      {labels?.isDelivery ? <TextLabel variant="delivery" /> : null}
      {labels?.isGlutenFree && <GlutenFreeLabel />}
      {labels?.isMadeInPoland && <MadeInPolandRectangleLabel />}
      {discount && <DiscountLabel discount={discount} />}
    </div>
  );
};

export default ProductTileLabels;
