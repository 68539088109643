import React from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import { EmptyState } from 'components/commercetools-ui/organisms/empty-state';
import { FooterLink } from 'components/commercetools-ui/organisms/footer/atoms/column';
import { useFormat } from 'helpers/hooks/useFormat';
import { useWishlist } from 'frontastic';
import { ImageProps } from 'frontastic/lib/image';
import WishlistItem from './components/wishlist-item';

export interface Props {
  emptyWishlistTitle: string;
  emptyWishlistSubtitle: string;
  emptyWishlistImage: ImageProps;
  emptyWishlistCategories: FooterLink[];
  handleCategoryClick?: () => void;
}
const Wishlist: React.FC<Props> = ({
  emptyWishlistTitle,
  emptyWishlistSubtitle,
  emptyWishlistImage,
  emptyWishlistCategories,
  handleCategoryClick,
}) => {
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
  const { data: wishlistData, clearWishlist } = useWishlist();
  const handleClearWishlist = async () => {
    if (wishlistData) await clearWishlist(wishlistData);
  };

  return (
    <div data-test="wishlist-panel">
      {!wishlistData?.lineItems?.length ? (
        <>
          <EmptyState
            title={emptyWishlistTitle}
            subtitle={emptyWishlistSubtitle}
            image={emptyWishlistImage}
            categories={emptyWishlistCategories}
            handleCategoryClick={handleCategoryClick}
          />
        </>
      ) : (
        <>
          <div className="mb-88 h-[83vh] grow divide-neutral-400 overflow-auto px-12 md:px-20 md:py-24">
            <div className="flex flex-col items-center gap-20 self-stretch py-8">
              {wishlistData?.lineItems?.map((lineItem, index) => (
                <>
                  <WishlistItem key={lineItem.lineItemId} item={lineItem} />
                  {index + 1 !== wishlistData?.lineItems?.length && (
                    <div className="w-full border-b border-neutral-400" />
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="shadow-custom absolute bottom-0 h-88 w-full p-20">
            <Button
              data-test="wishlist-panel-clear-list"
              onClick={handleClearWishlist}
              variant="secondary"
              className="w-full text-16"
            >
              {formatWishlistMessage({ id: 'wishlist.clear.list', defaultMessage: 'Clear the list' })}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default Wishlist;
