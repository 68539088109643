function CartIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7.154 21.558c-.486 0-.9-.17-1.24-.51-.34-.341-.51-.754-.51-1.24s.17-.9.51-1.24c.34-.34.754-.51 1.24-.51s.899.17 1.24.51c.34.34.51.754.51 1.24s-.17.899-.51 1.24c-.341.34-.754.51-1.24.51zm9.692 0c-.486 0-.899-.17-1.24-.51-.34-.341-.51-.754-.51-1.24s.17-.9.51-1.24c.341-.34.754-.51 1.24-.51s.9.17 1.24.51c.34.34.51.754.51 1.24s-.17.899-.51 1.24c-.34.34-.754.51-1.24.51zM6.016 5.75l2.534 5.308h6.817a.278.278 0 00.154-.044.378.378 0 00.116-.12l2.682-4.875c.039-.07.042-.133.01-.187-.032-.055-.087-.082-.164-.082H6.015zm-.72-1.5h13.885c.409 0 .718.174.928.522.21.348.22.704.03 1.066l-3.204 5.804a1.808 1.808 0 01-.651.674 1.7 1.7 0 01-.888.242H8.1l-1.158 2.115c-.051.077-.053.16-.005.25.049.09.12.135.217.135h10.692c.213 0 .391.072.535.215a.726.726 0 01.215.535.726.726 0 01-.215.534.726.726 0 01-.535.216H7.154c-.667 0-1.168-.288-1.503-.863-.335-.575-.347-1.149-.036-1.722l1.427-2.565L3.404 3.75H2.25a.726.726 0 01-.535-.215A.726.726 0 011.5 3c0-.213.072-.391.215-.535a.726.726 0 01.535-.215h1.529a.896.896 0 01.813.517l.704 1.483z"
      ></path>
    </svg>
  );
}

export default CartIcon;
