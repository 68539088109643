function GlutenFreeLabel({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20 * scale} height={20 * scale} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_257_6255)">
        <path fill="#4D4D4D" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10s4.477 10 10 10 10-4.477 10-10z"></path>
        <path
          fill="#fff"
          d="M12.891 5.046c1.118-1.118 3.297-1.17 3.297-1.17s-.052 2.178-1.17 3.296c-1.118 1.118-3.296 1.17-3.296 1.17s.051-2.178 1.17-3.296zM8.277 6.865c0-1.581 1.504-3.158 1.504-3.158s1.504 1.577 1.504 3.158-1.504 3.158-1.504 3.158-1.504-1.577-1.504-3.158zM13.135 11.723c1.581 0 3.158-1.503 3.158-1.503s-1.577-1.504-3.158-1.504-3.158 1.504-3.158 1.504 1.577 1.503 3.158 1.503zM5.636 9.507c0-1.582 1.504-3.158 1.504-3.158s1.503 1.576 1.503 3.158c0 1.58-1.503 3.157-1.503 3.157s-1.504-1.576-1.504-3.157zM10.494 14.364c1.581 0 3.158-1.503 3.158-1.503s-1.577-1.504-3.158-1.504-3.158 1.504-3.158 1.504 1.577 1.503 3.158 1.503zM2.994 12.148c0-1.581 1.504-3.158 1.504-3.158s1.504 1.577 1.504 3.158-1.504 3.158-1.504 3.158-1.504-1.577-1.504-3.158zM7.852 17.006c1.581 0 3.158-1.504 3.158-1.504s-1.577-1.504-3.158-1.504-3.158 1.504-3.158 1.504 1.577 1.504 3.158 1.504z"
        ></path>
        <path stroke="#fff" strokeLinecap="round" d="M2.434 3.707l3.089 3.089M13.204 14.364l3.089 3.09"></path>
      </g>
      <defs>
        <clipPath id="clip0_257_6255">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default GlutenFreeLabel;
