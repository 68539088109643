import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import { Transition } from '@headlessui/react';
import debounce from 'lodash.debounce';
import { Category } from 'shared/types/product';
import { Product } from 'shared/types/product/Product';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import CloseIcon from 'components/icons/CloseIcon';
import MagnifyingGlassIcon from 'components/icons/MagnifyingGlassIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useScrollBlock from 'helpers/hooks/useScrollBlock';
import { useProduct } from 'frontastic';
import SearchItem from './search-item';
import Button from '../button';
import LoadingIcon from '../button/loadingIcon';
import Overlay from '../overlay';

interface Props {
  categories: Category[];
}

const MIN_SEARCH_CHARS = 3;

const Search: React.FC<Props> = ({ categories }) => {
  const form = useRef<HTMLFormElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const { query: queryProducts } = useProduct();

  const [items, setItems] = useState<Product[]>([]);

  const [focused, setFocused] = useState(false);

  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatMessageProduct } = useFormat({ name: 'product' });

  const { blockScroll } = useScrollBlock();

  const router = useRouter();

  const [value, setValue] = useState('');
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    blockScroll(focused);
  }, [blockScroll, focused]);

  const fetchResults = useCallback(async () => {
    if (query.length < MIN_SEARCH_CHARS) return;

    const results = await queryProducts({ query, limit: 6 });

    setIsLoading(false);

    if (results.isError) return;

    setItems((results.data.items as Product[]) ?? []);
  }, [query, queryProducts]);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const updateQuery = useCallback(
    debounce((value: string) => setQuery(value), 300),
    [],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      updateQuery(e.target.value);
      setIsLoading(true);
    },
    [updateQuery],
  );

  const cleanUp = useCallback(() => {
    updateQuery('');
    setValue('');
  }, [updateQuery]);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (!value) return;

      input.current?.blur();
      router.push(`/search?query=${value}`);
    },
    [value, router],
  );

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  return (
    <>
      {focused && <Overlay />}

      <div className={`relative xl:px-20 ${focused ? 'z-[350]' : ''}`}>
        <div
          className={`relative z-50 overflow-hidden rounded-[52px] border-neutral-400 bg-white lg:border xl:rounded-[121px] ${
            focused ? 'border-b' : 'border'
          }`}
        >
          <form className="quick-search relative flex w-full items-stretch xl:h-48" ref={form} onSubmit={onSubmit}>
            <button
              type="submit"
              title={formatMessage({ id: 'search', defaultMessage: 'Search' })}
              className={`mr-6 shrink-0 pl-20 pr-12 transition xl:hidden ${focused ? 'bg-primary-black' : 'bg-white'}`}
            >
              <span className={`stroke-0 ${focused ? 'text-neutral-5' : 'text-neutral-4'}`}>
                <MagnifyingGlassIcon />
              </span>
            </button>

            <input
              data-test="search-input"
              ref={input}
              className="box-content grow border-none p-0 py-14 transition placeholder:text-14 placeholder:text-neutral-3 focus:outline-none xl:pl-21 xl:placeholder:text-16"
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={`${formatMessage({ id: 'search.placeholder', defaultMessage: 'Type to search' })}...`}
            />

            <button
              type="submit"
              title={formatMessage({ id: 'search', defaultMessage: 'Search' })}
              className={`hidden shrink-0 border-l border-neutral-400 p-12 pr-16 transition xl:block ${
                focused ? 'bg-primary-black' : 'bg-white'
              }`}
            >
              <span className={`stroke-0 ${focused ? 'text-neutral-5' : 'text-neutral-4'}`}>
                <MagnifyingGlassIcon />
              </span>
            </button>

            {value && (
              <button
                type="reset"
                className="absolute right-[70px] top-1/2 block -translate-y-1/2"
                onClick={cleanUp}
                onMouseDown={(e) => e.preventDefault()}
              >
                <CloseIcon />
              </button>
            )}
          </form>
        </div>

        <Transition
          show={focused && value.length >= MIN_SEARCH_CHARS}
          className="absolute bottom-0 left-0 max-h-[60vh] w-full translate-y-full overflow-auto bg-white px-20 py-28 xl:max-h-[unset] xl:translate-y-[calc(100%-56px)] xl:rounded-md xl:pt-84"
          enter="transition duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {isLoading && value.length >= MIN_SEARCH_CHARS && (
            <div className="flex justify-center">
              <LoadingIcon className="fill-gray-700" />
            </div>
          )}

          {!isLoading && (
            <>
              {/* Results list */}
              <div className="grid grid-cols-1 gap-30 md:grid-cols-2">
                {items.length === 0 ? (
                  <div>{formatMessageProduct({ id: 'noProductsFound', defaultMessage: 'No products found' })}</div>
                ) : (
                  items.map((item) => <SearchItem key={item.productId} hit={item} categories={categories} />)
                )}
              </div>

              {/* See all results button */}
              {items.length > 0 && (
                <div className="flex items-center justify-center">
                  <Button
                    variant="underlined"
                    icon={<ArrowRightIcon scale={0.84} />}
                    iconPosition="left"
                    onClick={onSubmit}
                  >
                    {formatMessage({ id: 'search.drawer.seeAllResults', defaultMessage: 'See all results' })}
                  </Button>
                </div>
              )}
            </>
          )}
        </Transition>
      </div>
    </>
  );
};

export default Search;
