import React, { FC } from 'react';
import { useRouter } from 'next/navigation';
import { Account } from 'shared/types/account';
import { Category } from 'shared/types/product/Category';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { Link as LinkType } from '../../../types';
import MobileMenuNavButton from '../atoms/menu-nav-button';

export interface Props {
  insertCategory: (category: Category) => void;
  hideHeaderMenu: () => void;
  account: Account | undefined;
  logout: () => Promise<void>;
  showMenu?: boolean;
  contentLinks?: LinkType[];
}

const MobileMenuFooter: FC<Props> = ({ hideHeaderMenu, insertCategory, contentLinks, account, logout }) => {
  const router = useRouter();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const tabs = [
    { name: formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' }), href: '/account' },
    { name: formatAccountMessage({ id: 'orders', defaultMessage: 'Orders' }), href: '/account/?hash=orders' },
    { name: formatAccountMessage({ id: 'addresses', defaultMessage: 'Addresses' }), href: '/account/?hash=addresses' },
    {
      name: formatAccountMessage({ id: 'customer.support', defaultMessage: 'Customer support' }),
      href: '/account?hash=support',
    },
  ];

  const accountButton: Category = {
    categoryId: 'myAccount',
    name: formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' }),
    depth: 1,
    productsAmount: 1,
    subCategories: tabs.map((tab) => {
      return { categoryId: tab.href, name: tab.name, path: tab.href, subCategories: [], depth: 1, productsAmount: 1 };
    }),
  };

  const handleLogout = () => {
    logout().then(() => router.push('/'));
    hideHeaderMenu();
  };

  const handleLogin = () => {
    router.push('/login');
    hideHeaderMenu();
  };

  return (
    <>
      <div className="mx-20 border-b border-neutral-2" />

      {account ? (
        <div className="ml-24 mr-22 block">
          <MobileMenuNavButton
            link={accountButton}
            onClick={() => insertCategory(accountButton)}
            hideHeaderMenu={hideHeaderMenu}
          />
        </div>
      ) : null}

      {contentLinks ? (
        <div className="ml-24 mr-22 mt-40 flex flex-col gap-y-24">
          {contentLinks.map((link) => (
            <Link key={link.name} link={link.reference} onClick={hideHeaderMenu} className="">
              <Typography className="text-18 text-neutral-3">{link.name}</Typography>
            </Link>
          ))}
        </div>
      ) : null}

      <div className="mt-auto border-t border-neutral-2 p-20">
        {account ? (
          <Button variant="secondary" onClick={handleLogout} className="w-full">
            <Typography className="text-16">
              {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
            </Typography>
          </Button>
        ) : (
          <Button variant="primary" onClick={handleLogin} className="w-full">
            <Typography className="text-16">
              {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
            </Typography>
          </Button>
        )}
      </div>
    </>
  );
};

export default MobileMenuFooter;
