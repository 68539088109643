function UserIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 11.667c-1.1 0-2.014-.364-2.742-1.092-.727-.728-1.091-1.642-1.091-2.742S8.53 5.82 9.258 5.092C9.986 4.364 10.9 4 12 4s2.014.364 2.742 1.092c.727.727 1.091 1.641 1.091 2.741s-.364 2.014-1.091 2.742c-.728.728-1.642 1.092-2.742 1.092zm-8 8.016v-2.5c0-.61.154-1.145.463-1.604a3 3 0 011.204-1.046 17.323 17.323 0 013.191-1.137 13.247 13.247 0 013.142-.38c1.056 0 2.1.13 3.133.388 1.034.258 2.094.636 3.182 1.133.51.24.917.588 1.224 1.045.307.456.461.99.461 1.601v2.5H4zm1.667-1.666h12.666v-.834c0-.239-.068-.464-.204-.675a1.33 1.33 0 00-.512-.475c-1.011-.494-1.964-.843-2.859-1.046A12.435 12.435 0 0012 14.684c-.944 0-1.87.102-2.775.305-.906.202-1.858.55-2.858 1.045a1.27 1.27 0 00-.505.475c-.13.211-.195.436-.195.675v.834zM12 10c.617 0 1.132-.207 1.546-.62.414-.415.62-.93.62-1.547 0-.616-.206-1.132-.62-1.546-.414-.413-.93-.62-1.546-.62-.617 0-1.132.207-1.546.62-.414.414-.62.93-.62 1.546 0 .617.206 1.132.62 1.546.414.414.93.621 1.546.621z"
      ></path>
    </svg>
  );
}

export default UserIcon;
