'use client';

import React from 'react';
import HeroSlider, { HeroSliderProps } from 'components/commercetools-ui/organisms/content/hero-slider';
import { TasticProps } from 'frontastic/tastics/types';

const HeroSliderTastic = ({ data }: TasticProps<HeroSliderProps>) => {
  return <HeroSlider autoplay={data.autoplay} delay={data.delay} slides={data.slides} />;
};

export default HeroSliderTastic;
