import React, { useCallback, useEffect, useRef, useState } from 'react';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import Typography from '../typography';

export interface CheckboxProps extends Omit<React.ComponentProps<'input'>, 'onChange' | 'key'> {
  label?: string;
  labelPosition?: 'on-left' | 'on-right';
  containerClassName?: string;
  onChange?: (props: { name: string; checked: boolean }) => void;
  disableBackground?: boolean;
  isValid?: boolean;
  errorMessage?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className = '',
  checked,
  onChange,
  onMouseOver,
  onMouseLeave,
  containerClassName,
  label,
  defaultChecked = false,
  labelPosition = 'on-right',
  disableBackground = false,
  isValid = true,
  errorMessage,
  ...props
}) => {
  // TODO: simplify, this looks over-engineered
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(checked ?? defaultChecked ?? false);

  const [isDesktopSize] = useMediaQuery(desktop);

  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof checked !== 'undefined') setIsChecked(checked);
  }, [checked]);

  const handleContainerClick = () => {
    if (!isDesktopSize) {
      toggleIsChecked();
    }
  };

  const toggleIsChecked = useCallback(() => {
    onChange?.({ name: props.name ?? '', checked: !isChecked });
    setIsChecked(!isChecked);
  }, [isChecked, onChange, props.name]);

  const handleMouseOver = (e: React.MouseEvent<HTMLInputElement>) => {
    setIsHovered(isDesktopSize);
    onMouseOver?.(e);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
    setIsHovered(false);
    onMouseLeave?.(e);
  };

  const containerClassNames = useClassNames(['flex items-center gap-12', containerClassName]);
  const buttonClassName = useClassNames([
    'h-20 w-20 min-w-[20px] relative bg-neutral-5',
    props.disabled ? '' : 'outline outline-1',
    !isValid ? 'outline-status-danger-border' : 'outline-base-accent-1',
  ]);

  const inputClassName = useClassNames([
    'absolute w-full h-full z-[2] checked:bg-none bg-transparent text-transparent border-transparent cursor-pointer disabled:cursor-default',
    className,
  ]);

  const iconClassName = useClassNames([
    'absolute top-[50%] z-[1] left-[50%] h-16 w-16 translate-y-[-50%] translate-x-[-50%] stroke-[2px]',
    isChecked && props.disabled ? '!text-base-accent-1' : '',
    isChecked || (isHovered && !props.disabled) ? 'block' : 'hidden',
    isChecked && !disableBackground ? 'text-neutral-5' : 'text-base-accent-1',
    !isValid && 'text-neutral-4',
  ]);

  const backgroundClassName = useClassNames([
    'absolute z-0 h-20 w-20',
    { 'bg-base-accent-1': !!isChecked },
    props.disabled ? 'bg-base-accent-2' : '',
  ]);

  const LabelElement = (
    <Typography as="label" className="text-16 leading-5 text-neutral-4">
      {label}
    </Typography>
  );

  return (
    <div className="flex flex-col">
      <div className={containerClassNames} onClick={handleContainerClick}>
        {label && labelPosition === 'on-left' && LabelElement}

        <div className={buttonClassName}>
          <input
            ref={checkboxRef}
            type="checkbox"
            checked={isChecked}
            className={inputClassName}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onChange={toggleIsChecked}
            {...props}
          />

          <span className={iconClassName}>
            <CheckmarkIcon scale={0.7} />
          </span>

          {!disableBackground && <div className={backgroundClassName} />}
        </div>

        {label && labelPosition === 'on-right' && LabelElement}
      </div>
      {!isValid && (
        <div className="mt-12 flex items-center gap-4">
          <span className="text-status-danger-border">
            <ExclamationMarkIcon />
          </span>
          <Typography className="text-14 font-medium text-status-danger-border" as="span">
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
