function MagnifyingGlassIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19.542 20.577l-6.28-6.28c-.5.412-1.075.735-1.726.968-.65.234-1.322.35-2.017.35-1.709 0-3.156-.591-4.34-1.775-1.183-1.183-1.775-2.63-1.775-4.338 0-1.709.592-3.156 1.775-4.34 1.184-1.185 2.63-1.777 4.339-1.777 1.708 0 3.155.592 4.34 1.776 1.184 1.183 1.776 2.63 1.776 4.34 0 .713-.12 1.395-.36 2.045-.239.65-.559 1.216-.959 1.696l6.281 6.281-1.054 1.054zM9.52 14.115c1.289 0 2.38-.447 3.274-1.34.894-.895 1.342-1.986 1.342-3.275 0-1.288-.448-2.38-1.342-3.274-.894-.894-1.985-1.341-3.274-1.341-1.288 0-2.38.447-3.274 1.341-.894.894-1.341 1.986-1.341 3.274 0 1.289.447 2.38 1.341 3.274.894.894 1.986 1.342 3.274 1.342z"
      ></path>
    </svg>
  );
}

export default MagnifyingGlassIcon;
