function ExclamationMarkIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_2268_11628)">
        <path
          d="M12.464 15.2C12.432 15.2107 12.3947 15.216 12.352 15.216H12.16C11.8613 15.216 11.6053 15.1413 11.392 14.992C11.1893 14.8427 11.088 14.5653 11.088 14.16V7.264H11.184C11.216 7.264 11.248 7.264 11.28 7.264C11.3227 7.25333 11.36 7.248 11.392 7.248C11.6907 7.248 11.9413 7.32267 12.144 7.472C12.3573 7.62133 12.464 7.89867 12.464 8.304V15.2ZM12.464 17.984C12.2933 18.1547 12.0747 18.24 11.808 18.24C11.5307 18.24 11.3067 18.1547 11.136 17.984C10.9653 17.8027 10.88 17.5733 10.88 17.296C10.88 17.0187 10.9653 16.7893 11.136 16.608C11.3067 16.416 11.5307 16.32 11.808 16.32C12.0747 16.32 12.2933 16.416 12.464 16.608C12.6347 16.7893 12.72 17.0187 12.72 17.296C12.72 17.5733 12.6347 17.8027 12.464 17.984Z"
          fill="currentColor"
        />
      </g>
      <rect x="3.5" y="3.5" width="18" height="18" rx="8.5" stroke="currentColor" />
    </svg>
  );
}

export default ExclamationMarkIcon;
