import { FC, useCallback, useState } from 'react';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import Login from '../../authentication/login';
import { ModalLogin } from '../../cart/types';

interface LoginSuggestionProps {
  modalLogin: ModalLogin;
}

const LoginSuggestion: FC<LoginSuggestionProps> = ({ modalLogin }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const openLoginModal = useCallback(() => setIsLoginModalOpen(true), []);
  const closeLoginModal = useCallback(() => setIsLoginModalOpen(false), []);

  return (
    <>
      <Typography className="mt-8">
        {formatCartMessage({
          id: 'order.summary.login',
          defaultMessage: 'Log in to use your personal offers!',
        })}
      </Typography>
      <Button className="mt-16 w-full" onClick={openLoginModal} size="full" variant="secondary">
        {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Log in' })}
      </Button>

      <Modal
        isOpen={isLoginModalOpen}
        onRequestClose={closeLoginModal}
        className="relative w-[95%] bg-white"
        style={{ content: { maxWidth: '900px', maxHeight: '95vh', overflowY: 'auto' } }}
        closeTimeoutMS={200}
      >
        <CloseIcon
          className="absolute right-12 top-12 h-24 w-24 cursor-pointer text-neutral-4 lg:right-24 lg:top-20"
          onClick={closeLoginModal}
        />
        <div className="px-12 py-32 lg:p-40">
          <Login onLogin={closeLoginModal} studio={modalLogin} />
        </div>
      </Modal>
    </>
  );
};

export default LoginSuggestion;
