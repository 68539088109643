import { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import ChevronUpIcon from 'components/icons/ChevronUpIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import { AccordionProps } from '.';
import Typography from '../typography';

type AccordionButtonProps = AccordionProps & {
  open: boolean;
  buttonWrapperClassName?: string;
  buttonClassName?: string;
  dataTest?: string | null;
};

const AccordionButton: FC<AccordionButtonProps> = ({
  open,
  buttonWrapperClassName,
  buttonClassName,
  openSectionTitle,
  closedSectionTitle,
  collapsedLabel,
  variant,
  iconClassName,
  dataTest = null,
  as,
}) => {
  const buttonClassNames = useClassNames(['w-full flex justify-between', buttonClassName]);

  return (
    <Disclosure.Button data-test={dataTest} className={`${buttonWrapperClassName} w-full`}>
      <div className={buttonClassNames}>
        <Typography className="self-center transition" as={as}>
          {open ? openSectionTitle : closedSectionTitle}
        </Typography>
        <div className="flex items-center gap-8">
          {!open && collapsedLabel && <p className="font-medium text-primary-black">{collapsedLabel}</p>}
          {variant === 'arrow' ? (
            <span className={`${open ? 'rotate-180' : ''} ${iconClassName} transition`}>
              <ChevronDownIcon />
            </span>
          ) : variant === 'arrow-filter' ? (
            <div className="flex h-32 w-32 items-center justify-center rounded-full bg-base-accent-2">
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          ) : !open ? (
            <PlusIcon width={17.5} strokeWidth={2} className={iconClassName} />
          ) : (
            <MinusIcon width={17.5} strokeWidth={2} className={iconClassName} />
          )}
        </div>
      </div>
    </Disclosure.Button>
  );
};

export default AccordionButton;
