function TruckIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5.844 19.558c-.78 0-1.443-.273-1.988-.82a2.71 2.71 0 01-.818-1.988H1.346V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h13.615v3.808h2.654l3.23 4.327v4.115h-1.807c0 .78-.273 1.443-.82 1.989a2.71 2.71 0 01-1.99.819c-.78 0-1.443-.273-1.988-.82a2.71 2.71 0 01-.817-1.988H8.654c0 .782-.273 1.445-.82 1.99a2.713 2.713 0 01-1.99.818zm.002-1.5c.367 0 .676-.127.929-.38.253-.252.379-.561.379-.928s-.126-.676-.379-.929a1.263 1.263 0 00-.929-.379c-.367 0-.676.127-.929.38a1.263 1.263 0 00-.379.928c0 .367.127.676.38.929.252.252.561.379.928.379zm-3-2.808h.723c.213-.37.52-.681.922-.932.402-.25.854-.376 1.355-.376.489 0 .937.124 1.345.371.409.248.72.56.932.937h7.146V6H3.154a.294.294 0 00-.212.096.294.294 0 00-.096.212v8.942zm15.192 2.808c.367 0 .677-.127.93-.38.252-.252.378-.561.378-.928s-.126-.676-.379-.929a1.263 1.263 0 00-.928-.379c-.367 0-.677.127-.93.38a1.263 1.263 0 00-.378.928c0 .367.126.676.379.929.252.252.562.379.928.379zM16.77 13.25h4.481l-2.596-3.442h-1.885v3.442z"
      ></path>
    </svg>
  );
}

export default TruckIcon;
