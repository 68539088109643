import React, { FC } from 'react';
import { Category } from 'shared/types/product/Category';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import CloseIcon from 'components/icons/CloseIcon';
import { useFormat } from 'helpers/hooks/useFormat';

export interface Props {
  categories: Category[];
  onArrowClick: () => void;
  hideHeaderMenu: () => void;
}

const MobileMenuHeader: FC<Props> = ({ onArrowClick, hideHeaderMenu, categories }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <div className="flex h-52 w-full justify-between border-b border-neutral-400 px-20 py-18">
      {categories.length > 0 && (
        <div className="flex h-full items-center justify-start">
          <Button variant="ghost" size="fit" onClick={onArrowClick} className="px-0">
            <ArrowLeftIcon />
          </Button>
        </div>
      )}
      <div className="flex w-full items-center justify-center">
        <Typography className="text-18 font-bold text-neutral-4">
          {categories[categories.length - 1]?.name ?? formatMessage({ id: 'menu', defaultMessage: 'Menu' })}
        </Typography>
      </div>
      <div className="flex h-full items-center justify-end">
        <Button
          variant="ghost"
          size="xs"
          onClick={hideHeaderMenu}
          title={formatMessage({ id: 'close', defaultMessage: 'Close' })}
          className="px-0 text-neutral-4"
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
};

export default MobileMenuHeader;
