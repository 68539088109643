function CloseIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M6.4 18.654L5.346 17.6l5.6-5.6-5.6-5.6L6.4 5.346l5.6 5.6 5.6-5.6L18.654 6.4l-5.6 5.6 5.6 5.6-1.054 1.054-5.6-5.6-5.6 5.6z"
      ></path>
    </svg>
  );
}

export default CloseIcon;
