import React, { FC } from 'react';
import { Category } from 'shared/types/product/Category';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { Tile } from '../../../types';
import MobileMenuNavButton from '../atoms/menu-nav-button';

export interface Props {
  links: Category[];
  hideHeaderMenu: () => void;
  categoriesNavigator?: Category[]; //This is a navigator where you push a subcategory to show it's contents
  insertCategory: (category: Category) => void;
  tiles: Tile[];
}

const MobileMenu: FC<Props> = ({ links, categoriesNavigator, insertCategory, hideHeaderMenu, tiles }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <div className="no-scrollbar ml-24 mr-22 flex min-h-max flex-col overflow-y-scroll overscroll-y-contain scroll-smooth">
      {categoriesNavigator && categoriesNavigator?.length > 0 && (
        <div className="pt-32">
          {categoriesNavigator[categoriesNavigator.length - 1]?.categoryId != 'myAccount' && (
            <div className="border-b border-neutral-2 pb-16" onClick={hideHeaderMenu}>
              <Link
                link={
                  categoriesNavigator[categoriesNavigator.length - 1]?.path ??
                  categoriesNavigator[categoriesNavigator.length - 1]?.slug
                }
              >
                <Typography className="text-18 text-neutral-4">
                  {formatMessage({ id: 'view.all', defaultMessage: 'View All' })}
                </Typography>
              </Link>
            </div>
          )}
        </div>
      )}
      {categoriesNavigator && categoriesNavigator.length <= 0 ? (
        <div className={'mt-32'}>
          {links?.map((link) => (
            <MobileMenuNavButton
              key={link.categoryId}
              categoriesNavigator={categoriesNavigator}
              link={link}
              onClick={() => insertCategory(link)}
              hideHeaderMenu={hideHeaderMenu}
            />
          ))}
        </div>
      ) : (
        categoriesNavigator &&
        categoriesNavigator[categoriesNavigator.length - 1].subCategories.map((nav) => (
          <MobileMenuNavButton
            key={nav.categoryId}
            categoriesNavigator={categoriesNavigator}
            link={nav}
            onClick={() => insertCategory(nav)}
            hideHeaderMenu={hideHeaderMenu}
          />
        ))
      )}
      <div className="flex flex-col gap-24 pb-24">
        {tiles.map((tile) => (
          <Link key={tile.tileCategory} link={tile.tileButtonLink} className="text-18 text-neutral-4">
            <span>{tile.tileCategory}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MobileMenu;
