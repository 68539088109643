function MadeInPolandRectangleLabel({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22 * scale} height={20 * scale} fill="none" viewBox="0 0 22 20">
      <g stroke="#ED1B2E" clipPath="url(#clip0_257_6256)">
        <path fill="#fff" d="M21.5 11.167V1.333H.5v9.834h21z"></path>
        <path fill="#ED1B2E" d="M21.5 19.5v-8.167H.5V19.5h21z"></path>
      </g>
      <defs>
        <clipPath id="clip0_257_6256">
          <path fill="#fff" d="M0 0H22V20H0z" transform="matrix(-1 0 0 1 22 0)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default MadeInPolandRectangleLabel;
