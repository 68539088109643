import { FC } from 'react';
import CartIcon from 'components/icons/CartIcon';
import MinusIcon from 'components/icons/MinusIcon';
import PlusIcon from 'components/icons/PlusIcon';
import TrashIcon from 'components/icons/TrashIcon';
import Button from '../button';

export interface CartButtonProps {
  addToCart: React.MouseEventHandler<HTMLButtonElement> | undefined;
  removeFromCart: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur: React.ChangeEventHandler<HTMLInputElement> | undefined;
  loadingAdd?: boolean;
  loadingRemove?: boolean;
  added?: boolean;
  removed?: boolean;
  quantity: number;
  inputQuantity: number;
}

const CartButton: FC<CartButtonProps> = ({
  addToCart,
  removeFromCart,
  onChange,
  onBlur,
  loadingAdd,
  loadingRemove,
  added,
  removed,
  quantity,
  inputQuantity,
}) => {
  if (quantity === 0) {
    return (
      <Button
        data-test="amount-control-add-to-cart-button"
        variant="primaryIconOnly"
        onClick={addToCart}
        loading={loadingAdd}
        updated={added}
        size="icon"
        icon={
          <div className="flex items-center">
            <CartIcon />
            <PlusIcon scale={0.75} />
          </div>
        }
      />
    );
  }
  const isRemoveDisabled = loadingAdd || added;
  const isAddDisabled = loadingRemove || removed;
  const removeIconClass = quantity === 1 ? 'text-neutral-4 hover:text-base-accent-1 disabled:text-neutral-1' : '';
  const disableRemoveClass = isRemoveDisabled ? 'cursor-not-allowed' : '';
  const removeClassName = removeIconClass + ' ' + disableRemoveClass;
  const disableAddClass = isAddDisabled ? 'cursor-not-allowed' : '';
  const disableInputClass = isRemoveDisabled || isAddDisabled ? 'cursor-not-allowed disabled:text-neutral-1' : '';
  const inputClass =
    disableInputClass +
    ' ' +
    'w-47 border-x-0 border-y-base-accent-2 bg-white p-0 text-center font-bold outline-none focus:border-base-accent-2 focus:outline-none no-spinner';

  return (
    <div className="flex w-full">
      <Button
        variant="removeFromCart"
        className={removeClassName}
        onClick={removeFromCart}
        loading={loadingRemove}
        updated={removed}
        disabled={isRemoveDisabled}
        size="cart"
        data-test="amount-control-minus-button"
      >
        {quantity === 1 ? <TrashIcon /> : <MinusIcon />}
      </Button>
      <input
        type="number"
        className={inputClass}
        value={inputQuantity > 0 ? inputQuantity : quantity}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isRemoveDisabled || isAddDisabled}
        data-test="amount-control-input"
      ></input>
      <Button
        variant="addToCart"
        className={disableAddClass}
        onClick={addToCart}
        loading={loadingAdd}
        updated={added}
        disabled={isAddDisabled}
        size="cart"
        data-test="amount-control-plus-button"
      >
        <PlusIcon />
      </Button>
    </div>
  );
};

export default CartButton;
